{
  "$schema": "./bibliotecas.schema.json",
  "bibliotecas": [
    {
      "nome": "Arquivos",
      "descrição": "Esta biblioteca permite ler e escrever arquivos",
      "constantes": [
        {
          "nome": "MODO_ACRESCENTAR",
          "valor": 2,
          "descrição": "indica à biblioteca que o arquivo deve ser aberto apenas para escrita\nque acrescenta ao final do arquivo\n",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "MODO_ESCRITA",
          "valor": 1,
          "descrição": "indica à biblioteca que o arquivo deve ser aberto apenas para escrita",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "MODO_LEITURA",
          "valor": 0,
          "descrição": "indica à biblioteca que o arquivo deve ser aberto apenas para leitura",
          "tipo": {
            "primitivo": "inteiro"
          }
        }
      ],
      "funções": [
        {
          "nome": "abrir_arquivo",
          "descrição": "Abre um arquivo para leitura ou escrita. No modo leitura, caso o arquivo informado não exista, será gerado um erro. No modo escrita, caso o arquivo informado não exista, ele tentará ser criado, se a criação do arquivo falhar, então será gerado um erro.\n\n**IMPORTANTE:** ao abrir o arquivo no modo de escrita, o conteúdo do arquivo é apagado para que o novo conteúdo seja escrito. Caso seja necessário manter o conteúdo atual do arquivo, deve-se armazená-lo em uma variável e depois escrevê-lo novamente no arquivo.",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "o endereço de memória onde o arquivo foi carregado"
          },
          "parâmetros": [
            {
              "nome": "caminho_arquivo",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "o nome do arquivo que se quer abrir"
            },
            {
              "nome": "modo_acesso",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "determina se o arquivo será aberto para leitura ou para escrita.\nConstantes aceitas: MODO_LEITURA | MODO_ESCRITA | MODO_ACRESCENTAR\n"
            }
          ]
        },
        {
          "nome": "apagar_arquivo",
          "descrição": "Remove um arquivo do sistema de arquivos",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": [
            {
              "nome": "caminho_arquivo",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "o caminho do arquivo que se quer apagar"
            }
          ]
        },
        {
          "nome": "arquivo_existe",
          "descrição": "Verifica se um determinado arquivo existe no sistema de arquivos",
          "retorno": {
            "tipo": {
              "primitivo": "logico"
            },
            "descrição": "`verdadeiro` se o arquivo existir\n"
          },
          "parâmetros": [
            {
              "nome": "caminho_arquivo",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "o caminho do arquivo que se quer verificar"
            }
          ]
        },
        {
          "nome": "criar_pasta",
          "descrição": "Cria pastas no caminho informado caso elas não existam",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": [
            {
              "nome": "caminho",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "Caminho onde as pastas serão criadas"
            }
          ]
        },
        {
          "nome": "escrever_linha",
          "descrição": "Escreve uma linha no arquivo. Esta função só é executada se o arquivo\nestiver aberto em modo de escrita. Se o arquivo estiver em modo de leitura,\nserá gerado um erro.\n",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": [
            {
              "nome": "linha",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "a linha a ser escrita no arquivo"
            },
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço de memória do arquivo"
            }
          ]
        },
        {
          "nome": "fechar_arquivo",
          "descrição": "Fecha um arquivo aberto anteriormente",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            },
            "descrição": ""
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço de memória do arquivo"
            }
          ]
        },
        {
          "nome": "fim_arquivo",
          "descrição": "Verifica se o arquivo chegou ao fim, isto é, se todas as linhas já\nforam lidas. Esta função só é executada se o arquivo estiver aberto em modo\nde leitura. Se o arquivo estiver em modo de escrita, será gerado um erro.\n",
          "retorno": {
            "tipo": {
              "primitivo": "logico"
            },
            "descrição": "`verdadeiro` se o arquivo tiver chegado ao fim. Caso contrário\nretorna `falso`\n"
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço de memória do arquivo"
            }
          ]
        },
        {
          "nome": "ler_linha",
          "descrição": "Lê a próxima linha do arquivo. Esta função só é executada se o arquivo\nestiver aberto em modo de leitura. Se o arquivo estiver em modo de escrita,\nserá gerado um erro.\n",
          "retorno": {
            "tipo": {
              "primitivo": "cadeia"
            },
            "descrição": "Uma `cadeia` contendo o conteúdo da linha lida."
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço de memória do arquivo"
            }
          ]
        },
        {
          "nome": "listar_arquivos",
          "descrição": "Altera um vetor para que ele represente os arquivos existentes em um\ndiretório\n",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": [
            {
              "nome": "caminho_pai",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "Define o diretório cujas pastas serão listadas"
            },
            {
              "nome": "vetor_arquivos",
              "tipo": {
                "primitivo": "cadeia",
                "dimensão": "vetor"
              },
              "descrição": "Vetor destino que contará com os arquivos encontrados"
            }
          ]
        },
        {
          "nome": "listar_arquivos_por_tipo",
          "descrição": "Altera um vetor para que ele represente os arquivos existentes em um\ndiretório\n",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": [
            {
              "nome": "caminho_pai",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "Define o diretório cujas pastas serão listadas"
            },
            {
              "nome": "vetor_arquivos",
              "tipo": {
                "primitivo": "cadeia",
                "dimensão": "vetor"
              },
              "descrição": "Vetor destino que contará com os arquivos encontrados"
            },
            {
              "nome": "vetor_tipos",
              "tipo": {
                "primitivo": "cadeia",
                "dimensão": "vetor"
              },
              "descrição": "Vetor destino que contará com os tipos de arquivos encontrados"
            }
          ]
        },
        {
          "nome": "listar_pastas",
          "descrição": "Altera um vetor para que ele represente as pastas existentes em um\ndiretório\n",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": [
            {
              "nome": "caminho_pai",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "Define o diretório cujas pastas serão listadas"
            },
            {
              "nome": "vetor_pastas",
              "tipo": {
                "primitivo": "cadeia",
                "dimensão": "vetor"
              },
              "descrição": "Vetor destino que contará com as pastas encontradas"
            }
          ]
        },
        {
          "nome": "selecionar_arquivo",
          "descrição": "Abre uma janela que permite ao usuário navegar nos diretórios do computador e selecionar um arquivo\n",
          "retorno": {
            "tipo": {
              "primitivo": "cadeia"
            },
            "descrição": "O arquivo selecionado ou uma `cadeia` vazia caso o usuário tenha cancelado.\n"
          },
          "parâmetros": [
            {
              "nome": "formatos_suportados",
              "tipo": {
                "primitivo": "cadeia",
                "dimensão": "vetor"
              },
              "descrição": "Define os formatos de arquivos que poderão ser selecionados. Um formato de arquivo é formado por uma descrição e uma lista de extensões válidas.\nA descrição deve estar separada da lista de extensões pelo caracter '|' e cada extensão deverá estar separada da outra pelo caracter ','. Ex.: 'Arquivos de texto|txt', 'Arquivos de imagem|png,jpg,jpeg,bmp'\n"
            },
            {
              "nome": "aceitar_todos_arquivos",
              "tipo": {
                "primitivo": "logico"
              },
              "descrição": "Quando `verdadeiro`, inclui automaticamente um formato que permite selecionar qualquer arquivo. Este formato também será incluído se nenhum outro formato for informado no parâmetro 'formatos_suportados'\n"
            }
          ]
        },
        {
          "nome": "substituir_texto",
          "descrição": "Pesquisa por um determinado texto no arquivo e substitui todas as ocorrências por um texto alternativo\n",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "o endereço do arquivo"
            },
            {
              "nome": "texto_pesquisa",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "o texto que será pesquisado no arquivo"
            },
            {
              "nome": "texto_substituto",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "o texto pelo qual as ocorrências serão substituídas"
            },
            {
              "nome": "primeira_ocorrencia",
              "tipo": {
                "primitivo": "logico"
              },
              "descrição": "confirma se substituirá apenas a primeira ocorrência no texto, caso contrário, substituirá todas\n"
            }
          ]
        }
      ]
    },
    {
      "nome": "Calendario",
      "descrição": "Esta biblioteca é utilizada para retornar a data e(ou) hora do computador",
      "constantes": [
        {
          "nome": "DIA_DOMINGO",
          "valor": 1,
          "descrição": "constante que representa o 'Domingo'",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "DIA_QUARTA_FEIRA",
          "valor": 4,
          "descrição": "constante que representa a 'Quarta-Feira'",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "DIA_QUINTA_FEIRA",
          "valor": 5,
          "descrição": "constante que representa a 'Quinta-Feira'",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "DIA_SABADO",
          "valor": 7,
          "descrição": "constante que representa o 'Sábado'",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "DIA_SEGUNDA_FEIRA",
          "valor": 2,
          "descrição": "constante que representa a 'Segunda-Feira'",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "DIA_SEXTA_FEIRA",
          "valor": 6,
          "descrição": "constante que representa a 'Sexta-Feira'",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "DIA_TERCA_FEIRA",
          "valor": 3,
          "descrição": "constante que representa a 'Terça-Feira'",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "MES_ABRIL",
          "valor": 4,
          "descrição": "constante que representa o mês de 'Abril'",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "MES_AGOSTO",
          "valor": 8,
          "descrição": "constante que representa o mês de 'Agosto'",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "MES_DEZEMBRO",
          "valor": 12,
          "descrição": "constante que representa o mês de 'Dezembro'",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "MES_FEVEREIRO",
          "valor": 2,
          "descrição": "constante que representa o mês de 'Fevereiro'",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "MES_JANEIRO",
          "valor": 1,
          "descrição": "constante que representa o mês de 'Janeiro'",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "MES_JULHO",
          "valor": 7,
          "descrição": "constante que representa o mês de 'Julho'",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "MES_JUNHO",
          "valor": 6,
          "descrição": "constante que representa o mês de 'Junho'",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "MES_MAIO",
          "valor": 5,
          "descrição": "constante que representa o mês de 'Maio'",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "MES_MARCO",
          "valor": 3,
          "descrição": "constante que representa o mês de 'Março'",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "MES_NOVEMBRO",
          "valor": 11,
          "descrição": "constante que representa o mês de 'Novembro'",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "MES_OUTUBRO",
          "valor": 10,
          "descrição": "constante que representa o mês de 'Outubro'",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "MES_SETEMBRO",
          "valor": 9,
          "descrição": "constante que representa o mês de 'Setembro'",
          "tipo": {
            "primitivo": "inteiro"
          }
        }
      ],
      "funções": [
        {
          "nome": "ano_atual",
          "descrição": "Recupera o ano atual do computador.",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "um `inteiro` com o ano. Ex: 2012.\n"
          },
          "parâmetros": []
        },
        {
          "nome": "dia_mes_atual",
          "descrição": "Recupera o dia no mês atual do computador.",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "um `inteiro` com o dia no mês com dois dígitos, se forem menores que 10 apenas com um dígito. Ex: 26.\n"
          },
          "parâmetros": []
        },
        {
          "nome": "dia_semana_abreviado",
          "descrição": "De acordo com o valor de 1 a 7 informado retornará um dia da semana abreviado.",
          "retorno": {
            "tipo": {
              "primitivo": "cadeia"
            },
            "descrição": "uma `cadeia` com o dia da semana abreviado. Ex: Seg, para Segunda-Feira.\n"
          },
          "parâmetros": [
            {
              "nome": "numero_dia",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "um `inteiro` referente a um dia da semana"
            },
            {
              "nome": "caixa_alta",
              "tipo": {
                "primitivo": "logico"
              },
              "descrição": "lógico para retorno em caracteres maiúsculos"
            },
            {
              "nome": "caixa_baixa",
              "tipo": {
                "primitivo": "logico"
              },
              "descrição": "lógico para retorno em caracteres minúsculos"
            }
          ]
        },
        {
          "nome": "dia_semana_atual",
          "descrição": "Recupera o dia da semana de 1 a 7.",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "um `inteiro` com o dia da semana. Ex: 1, para Domingo.\n"
          },
          "parâmetros": []
        },
        {
          "nome": "dia_semana_completo",
          "descrição": "De acordo com o valor de 1 a 7 informado retornará um dia da semana completo.",
          "retorno": {
            "tipo": {
              "primitivo": "cadeia"
            },
            "descrição": "uma cadeia com o dia da semana completo. Ex: Segunda-Feira.\n"
          },
          "parâmetros": [
            {
              "nome": "numero_dia",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "um `inteiro` referente a um dia da semana"
            },
            {
              "nome": "caixa_alta",
              "tipo": {
                "primitivo": "logico"
              },
              "descrição": "lógico para retorno em caracteres maiúsculos"
            },
            {
              "nome": "caixa_baixa",
              "tipo": {
                "primitivo": "logico"
              },
              "descrição": "lógico para retorno em caracteres minúsculos"
            }
          ]
        },
        {
          "nome": "dia_semana_curto",
          "descrição": "De acordo com o valor de 1 a 7 informado retornará um dia da semana de forma curta.",
          "retorno": {
            "tipo": {
              "primitivo": "cadeia"
            },
            "descrição": "uma `cadeia` com o dia da semana de forma curta. Ex: Segunda, para Segunda-Feira.\n"
          },
          "parâmetros": [
            {
              "nome": "numero_dia",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "um `inteiro` referente a um dia da semana"
            },
            {
              "nome": "caixa_alta",
              "tipo": {
                "primitivo": "logico"
              },
              "descrição": "lógico para retorno em caracteres maiúsculos"
            },
            {
              "nome": "caixa_baixa",
              "tipo": {
                "primitivo": "logico"
              },
              "descrição": "lógico para retorno em caracteres minúsculos"
            }
          ]
        },
        {
          "nome": "hora_atual",
          "descrição": "Recupera os dígitos da hora atual do computador.",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "um `inteiro` com a hora atual no formato 12h ou 24h com dois dígitos, se forem menores que 10 apenas com um dígito. Ex: 22 para 24h, se o parâmetro for falso ou 10 para 12h, se o parâmetro for verdadeiro.\n"
          },
          "parâmetros": [
            {
              "nome": "formato_12h",
              "tipo": {
                "primitivo": "logico"
              },
              "descrição": "um lógico que se verdadeiro o retorno será no formato 12h se falso será em 24h"
            }
          ]
        },
        {
          "nome": "mes_atual",
          "descrição": "Recupera o mês atual do computador de 1 a 12.",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "um `inteiro` com o mês com dois dígitos, se forem menores que 10 apenas com um dígito. Ex: 10.\n"
          },
          "parâmetros": []
        },
        {
          "nome": "milisegundo_atual",
          "descrição": "Recupera os dígitos dos milissegundos atuais do computador.",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "um `inteiro` com os milissegundos atuais, com um, dois ou três dígitos. Ex: 426.\n"
          },
          "parâmetros": []
        },
        {
          "nome": "minuto_atual",
          "descrição": "Recupera os dígitos do minuto atual do computador.",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "um `inteiro` com os minutos atuais com dois dígitos, se forem menores que 10 apenas com um dígito. Ex: 45.\n"
          },
          "parâmetros": []
        },
        {
          "nome": "segundo_atual",
          "descrição": "Recupera os dígitos dos segundos atuais do computador.",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "um `inteiro` com os segundos atuais com dois dígitos, se forem menores que 10 apenas com um dígito. Ex: 32.\n"
          },
          "parâmetros": []
        }
      ]
    },
    {
      "nome": "Graficos",
      "descrição": "Esta biblioteca permite inicializar e utilizar um ambiente gráfico com suporte ao desenho de primitivas gráficas e de imagens carregadas do sistema de arquivos",
      "constantes": [
        {
          "nome": "CANAL_B",
          "valor": 2,
          "descrição": "constante que representa o canal 'AZUL'",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "CANAL_G",
          "valor": 1,
          "descrição": "constante que representa o canal 'VERDE'",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "CANAL_R",
          "valor": 0,
          "descrição": "constante que representa o canal 'VERMELHO'",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "COR_AMARELO",
          "valor": 16776960,
          "descrição": "constante que representa a cor 'amarelo'",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "COR_AZUL",
          "valor": 255,
          "descrição": "constante que representa a cor 'azul'",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "COR_BRANCO",
          "valor": 16777215,
          "descrição": "constante que representa a cor 'branca'",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "COR_PRETO",
          "valor": 0,
          "descrição": "constante que representa a cor 'preto'",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "COR_VERDE",
          "valor": 65280,
          "descrição": "constante que representa a cor 'verde'",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "COR_VERMELHO",
          "valor": 16711680,
          "descrição": "constante que representa a cor 'vermelho'",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "GRADIENTE_ABAIXO",
          "valor": 3,
          "descrição": "constante que representa o gradiente na rotação 0",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "GRADIENTE_ACIMA",
          "valor": 2,
          "descrição": "constante que representa o gradiente na rotação 0",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "GRADIENTE_DIREITA",
          "valor": 0,
          "descrição": "constante que representa o gradiente na rotação 0",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "GRADIENTE_ESQUERDA",
          "valor": 1,
          "descrição": "constante que representa o gradiente na rotação 0",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "GRADIENTE_INFERIOR_DIREITO",
          "valor": 4,
          "descrição": "constante que representa o gradiente na rotação inferior direito",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "GRADIENTE_INFERIOR_ESQUERDO",
          "valor": 5,
          "descrição": "constante que representa o gradiente na rotação inferior direito",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "GRADIENTE_SUPERIOR_DIREITO",
          "valor": 6,
          "descrição": "constante que representa o gradiente na rotação inferior direito",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "GRADIENTE_SUPERIOR_ESQUERDO",
          "valor": 7,
          "descrição": "constante que representa o gradiente na rotação inferior direito",
          "tipo": {
            "primitivo": "inteiro"
          }
        }
      ],
      "funções": [
        {
          "nome": "altura_imagem",
          "descrição": "Obtém a altura em pixels de uma imagem previamente carregada no ambiente gráfico\n",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "a altura da imagem"
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço da imagem para a qual se quer obter a altura"
            }
          ]
        },
        {
          "nome": "altura_janela",
          "descrição": "obtém a altura atual da janela do ambiente gráfico",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            }
          },
          "parâmetros": []
        },
        {
          "nome": "altura_tela",
          "descrição": "obtém a altura da tela do computador",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            }
          },
          "parâmetros": []
        },
        {
          "nome": "altura_texto",
          "descrição": "Obtém a altura em pixels que um texto ocupa para ser desenhado na tela\n",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "a altura do texto"
          },
          "parâmetros": [
            {
              "nome": "texto",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "o texto que será mensurado"
            }
          ]
        },
        {
          "nome": "carregar_fonte",
          "descrição": "Carrega uma fonte no ambiente gráfico a partir de um arquivo de fonte presente no sistema de arquivos",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": [
            {
              "nome": "caminho_fonte",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "o caminho do arquivo de fonte no sistema de arquivos"
            }
          ]
        },
        {
          "nome": "carregar_imagem",
          "descrição": "Carrega uma imagem na memória para ser utilizada mais tarde. Os formatos de imagem suportados\nsão: JPEG, PNG, BITMAP e GIF\n",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "o endereço de memória no qual a imagem foi carregada"
          },
          "parâmetros": [
            {
              "nome": "caminho",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "o caminho do arquivo de imagem no computador"
            }
          ]
        },
        {
          "nome": "criar_cor",
          "descrição": "cria uma nova cor a partir da combinação de tons de vermelho, verde e azul\n",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "a nova cor criada pela combinação dos tons de vermelho, verde e azul"
          },
          "parâmetros": [
            {
              "nome": "vermelho",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o tom de vermelho (0 a 255)"
            },
            {
              "nome": "verde",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o tom de verde (0 a 255)"
            },
            {
              "nome": "azul",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o tom de azul (0 a 255)"
            }
          ]
        },
        {
          "nome": "definir_cor",
          "descrição": "Define a cor atual do ambiente gráfico. Esta cor será utilizada para desenhar e preencher as primitivas gráficas (ponto, linha, retângulo, etc.) e, como cor de fundo ao limpar o ambiente gráfico ou desenhar um texto\n",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": [
            {
              "nome": "cor",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "a nova cor do ambiente gráfico"
            }
          ]
        },
        {
          "nome": "definir_dimensoes_janela",
          "descrição": "altera as dimensões da janela do ambiente gráfico",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": [
            {
              "nome": "largura",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "a nova largura da janela"
            },
            {
              "nome": "altura",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "a nova altura da janela"
            }
          ]
        },
        {
          "nome": "definir_estilo_texto",
          "descrição": "Define o estilo da fonte que será utilizada para desenhar um texto no ambiente gráfico",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": [
            {
              "nome": "italico",
              "tipo": {
                "primitivo": "logico"
              },
              "descrição": "define se a fonte terá o estilo itálico"
            },
            {
              "nome": "negrito",
              "tipo": {
                "primitivo": "logico"
              },
              "descrição": "define se a fonte terá o estilo negrito"
            },
            {
              "nome": "sublinhado",
              "tipo": {
                "primitivo": "logico"
              },
              "descrição": "define se a fonte terá o estilo sublinhado"
            }
          ]
        },
        {
          "nome": "definir_fonte_texto",
          "descrição": "Define a fonte que será utilizada para desenhar um texto no ambiente gráfico",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": [
            {
              "nome": "nome",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "o nome da fonte a ser utilizada (Ex.: Arial, Times New Roman, Tahoma). Se a fonte informada não existir no sistema operacional do computador, será utilizada a fonte padrão\n"
            }
          ]
        },
        {
          "nome": "definir_gradiente",
          "descrição": "Define a cor atual do ambiente gráfico como um gradiente de 2 cores. Esta cor será utilizada para desenhar e preencher as primitivas gráficas (ponto, linha, retângulo, etc.) e, como cor de fundo ao limpar o ambiente gráfico\n",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": [
            {
              "nome": "tipo",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o tipo de rotação de gradiente que será utilizado"
            },
            {
              "nome": "cor1",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "a primeira cor do novo gradiente do ambiente gráfico"
            },
            {
              "nome": "cor2",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "a segunda cor do novo gradiente do ambiente gráfico"
            }
          ]
        },
        {
          "nome": "definir_icone_janela",
          "descrição": "Altera o ícone que é exibido na janela do ambiente gráfico. Este ícone aparece ao lado do título da janela e na barra de tarefas do sistema operacional",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço da imagem que será usada como ícone"
            }
          ]
        },
        {
          "nome": "definir_opacidade",
          "descrição": "Esta função define o nível de opacidade dos desenhos no ambiente gráfico. Quanto menor for a opacidade, mais transparente será o desenho e quanto maior for a opacidade mais opaco será o desenho. Com esta função, é possível desenhar imagens, textos e primitivas gráficas semi-transparentes, o que permite \"enxergar\" através dos desenhos.\n\nÉ importante notar que, após ser chamada, esta função afeta todos os desenhos realizados. Isto significa que se foram desenhados um retângulo e uma elipse após a chamada desta função, ambos terão seu nível de opacidade alterados.\n\nCaso fosse desejável modificar apenas a opacidade do retângulo, então seria necessário chamar novamente esta função definindo a opacidade para o valor máximo antes de desenhar a elipse\n",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": [
            {
              "nome": "opacidade",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o nível de opacidade dos desenhos. O valor deve estar entre 0 e 255, sendo que, 0 indica um desenho totalmente transparente e 255 indica um desenho totalmente opaco\n"
            }
          ]
        },
        {
          "nome": "definir_quadro_gif",
          "descrição": "Define determinado quadro a um gif",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço de memória do gif"
            },
            {
              "nome": "quadro",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o quadro que deseja ser definido"
            }
          ]
        },
        {
          "nome": "definir_rotacao",
          "descrição": "Esta função define o grau de rotação dos desenhos no ambiente gráfico. Com esta função, é possível rotacionar imagens, textos e todas as primitivas gráficas, incluindo linhas, retângulos e elipses.\n\nA rotação é realizada sempre a partir do centro do desenho.\n\nÉ importante notar que, após ser chamada, esta função afeta todos os desenhos realizados.\nIsto significa que se foram desenhados um retângulo e uma elipse após a chamada desta função, ambos serão rotacionados no mesmo grau de inclinação.\n\nCaso fosse desejável rotacionar apenas o retângulo, então seria necessário chamar novamente esta função definindo a rotação para 0 antes de desenhar a elipse\n",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": [
            {
              "nome": "rotacao",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o grau de rotação dos desenhos. Pode ser qualquer valor real, incluindo 0, positivos e negativos.\nOs valores múltiplos de 360.0 e o valor 0.0 indicam que não haverá rotação. Valores positivos, indicam uma rotação no sentido horário enquanto que valores negativos indicam uma rotação no sentido anti-horário\n"
            }
          ]
        },
        {
          "nome": "definir_tamanho_texto",
          "descrição": "Define o tamanho da fonte que será utilizada para desenhar um texto no ambiente gráfico",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": [
            {
              "nome": "tamanho",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o tamanho da fonte a ser utilizada"
            }
          ]
        },
        {
          "nome": "definir_titulo_janela",
          "descrição": "define o texto da janela do ambiente gráfico",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": [
            {
              "nome": "titulo",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "o novo título da janela"
            }
          ]
        },
        {
          "nome": "desenhar_elipse",
          "descrição": "Desenha uma elipse na posição definida pelos parâmetros `x` e `y`\ne com as dimensões especificadas pelos parâmetros `largura` e `altura`.\n\nA elipse é desenhada na tela a partir do seu canto superior esquerdo\n",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": [
            {
              "nome": "x",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "a posição (distância) do círculo no eixo horizontal, em relação ao lado esquerdo da janela"
            },
            {
              "nome": "y",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "a posição (distância) do círculo no eixo vertical, em relação ao topo da janela"
            },
            {
              "nome": "largura",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "a largura da elipse em pixels"
            },
            {
              "nome": "altura",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "a altura da elipse em pixels"
            },
            {
              "nome": "preencher",
              "tipo": {
                "primitivo": "logico"
              },
              "descrição": "define se a elipse será preenchida com a cor do ambiente gráfico.\nSe o valor for `verdadeiro`, a elipse será preenchida. Se o valor for\n`falso`, somente o contorno da elipse será desenhado\n"
            }
          ]
        },
        {
          "nome": "desenhar_imagem",
          "descrição": "Desenha uma imagem previamente carregada, na posição especificada pelos parâmetros `x` e `y`\n",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": [
            {
              "nome": "x",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "a posição (distância) da imagem no eixo horizontal, em relação ao lado esquerdo da janela"
            },
            {
              "nome": "y",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "a posição (distância) da imagem no eixo vertical, em relação ao topo da janela"
            },
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço de memória da imagem a ser desenhada"
            }
          ]
        },
        {
          "nome": "desenhar_linha",
          "descrição": "Desenha uma linha de um ponto 'A' (definido pelos parâmetros `x1` e `y1`)\naté um ponto 'B' (definido pelos parâmetros `x2` e `y2`)\n",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": [
            {
              "nome": "x1",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "a coordenada (distância) do ponto 'A' no eixo horizontal, em relação ao lado esquerdo da janela"
            },
            {
              "nome": "y1",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "a coordenada (distância) do ponto 'A' no eixo vertical, em relação ao topo da janela"
            },
            {
              "nome": "x2",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "a coordenada (distância) do ponto 'B' no eixo horizontal, em relação ao lado esquerdo da janela"
            },
            {
              "nome": "y2",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "a coordenada (distância) do ponto 'B' no eixo vertical, em relação ao topo da janela"
            }
          ]
        },
        {
          "nome": "desenhar_poligono",
          "descrição": "Desenha poligono",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": [
            {
              "nome": "pontos",
              "tipo": {
                "primitivo": "inteiro",
                "dimensão": "matriz"
              },
              "descrição": "a largura do retângulo em pixels"
            },
            {
              "nome": "preencher",
              "tipo": {
                "primitivo": "logico"
              },
              "descrição": "define se o polígono será preenchido com a cor do ambiente gráfico.\nSe o valor for `verdadeiro`, o polígono será preenchido. Se o valor for `falso`, somente o contorno do polígono será desenhado\n"
            }
          ]
        },
        {
          "nome": "desenhar_ponto",
          "descrição": "Desenha um ponto na posição definida pelos parâmetros `x` e `y`.\n\nO ponto desenhado ocupa um único pixel na tela\n",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": [
            {
              "nome": "x",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "a coordenada (distância) do ponto no eixo horizontal, em relação ao lado esquerdo da janela"
            },
            {
              "nome": "y",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "a coordenada (distância) do ponto no eixo vertical, em relação ao topo da janela"
            }
          ]
        },
        {
          "nome": "desenhar_porcao_imagem",
          "descrição": "Desenha uma porção de uma imagem previamente carregada, na posição especificada pelos parâmetros `x` e `y`\n",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": [
            {
              "nome": "x",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "a posição (distância) da imagem no eixo horizontal, em relação ao lado esquerdo da janela"
            },
            {
              "nome": "y",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "a posição (distância) da imagem no eixo vertical, em relação ao topo da janela"
            },
            {
              "nome": "xi",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "a posição (distância) no eixo horizontal a partir da qual a imagem começará a ser desenhada"
            },
            {
              "nome": "yi",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "a posição (distância) no eixo vertical a partir da qual a imagem começará a ser desenhada"
            },
            {
              "nome": "largura",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "a largura da porção da imagem a ser desenhada"
            },
            {
              "nome": "altura",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "a altura da porção da imagem a ser desenhada"
            },
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço de memória da imagem a ser desenhada"
            }
          ]
        },
        {
          "nome": "desenhar_quadro_atual_gif",
          "descrição": "Desenha um frame de um gif previamente carregado, na posição especificada pelos parâmetros `x` e `y`\n",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": [
            {
              "nome": "x",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "a posição (distância) da imagem no eixo horizontal, em relação ao lado esquerdo da janela"
            },
            {
              "nome": "y",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "a posição (distância) da imagem no eixo vertical, em relação ao topo da janela"
            },
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço de memória da imagem a ser desenhada"
            }
          ]
        },
        {
          "nome": "desenhar_retangulo",
          "descrição": "Desenha um retângulo na posição definida pelos parâmetros `x` e `y` e com as dimensões especificadas pelos parâmetros `largura` e `altura`.\n\nO retângulo é desenhado na tela a partir do seu canto superior esquerdo\n",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": [
            {
              "nome": "x",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "a posição (distância) do retângulo no eixo horizontal, em relação ao lado esquerdo da janela"
            },
            {
              "nome": "y",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "a posição (distância) do retângulo no eixo vertical, em relação ao topo da janela"
            },
            {
              "nome": "largura",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "a largura do retângulo em pixels"
            },
            {
              "nome": "altura",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "a altura do retângulo em pixels"
            },
            {
              "nome": "arredondar_cantos",
              "tipo": {
                "primitivo": "logico"
              },
              "descrição": "define se o retângulo deverá ter cantos arredondados"
            },
            {
              "nome": "preencher",
              "tipo": {
                "primitivo": "logico"
              },
              "descrição": "define se o retângulo será preenchido com a cor do ambiente gráfico.\nSe o valor for `verdadeiro`, o retângulo será preenchido. Se o valor for `falso`, somente o contorno do retângulo será desenhado\n"
            }
          ]
        },
        {
          "nome": "desenhar_texto",
          "descrição": "Desenha um texto (`cadeia`) na posição especificada pelos parâmetros `x` e `y`\n",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": [
            {
              "nome": "x",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "a posição (distância) do texto no eixo horizontal, em relação ao lado esquerdo da janela"
            },
            {
              "nome": "y",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "a posição (distância) do ponto no eixo vertical, em relação ao topo da janela"
            },
            {
              "nome": "texto",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "o texto (`cadeia`) a ser desenhado"
            }
          ]
        },
        {
          "nome": "encerrar_modo_grafico",
          "descrição": "Encerra o modo gráfico e fecha a janela criada com a função `iniciar_modo_grafico`",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": []
        },
        {
          "nome": "entrar_modo_tela_cheia",
          "descrição": "Faz com que a janela gráfica seja redimensionada para ocupar o tamanho total da tela",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": []
        },
        {
          "nome": "exibir_borda_janela",
          "descrição": "Exibe novamente a borda da janela do modo gráfico, caso ela esteja oculta",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": []
        },
        {
          "nome": "fechar_janela",
          "descrição": "Encerra o programa como se o usuário tivesse clicado no botão 'Fechar' da janela",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": []
        },
        {
          "nome": "iniciar_modo_grafico",
          "descrição": "Inicia o modo gráfico e exibe uma janela com as configurações padrão (tamanho 640x480 e fundo preto).\nSe o modo gráfico já estiver iniciado, nada acontecerá\n",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": [
            {
              "nome": "manter_visivel",
              "tipo": {
                "primitivo": "logico"
              },
              "descrição": "define se a janela do ambiente gráfico deve permanecer sempre visível sobre as outras janelas (útil durante a depuração)"
            }
          ]
        },
        {
          "nome": "largura_imagem",
          "descrição": "Obtém a largura em pixels de uma imagem previamente carregada no ambiente gráfico\n",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "a largura da imagem"
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço da imagem para a qual se quer obter a largura"
            }
          ]
        },
        {
          "nome": "largura_janela",
          "descrição": "obtém a largura atual da janela do ambiente gráfico",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            }
          },
          "parâmetros": []
        },
        {
          "nome": "largura_tela",
          "descrição": "obtém a largura da tela do computador",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            }
          },
          "parâmetros": []
        },
        {
          "nome": "largura_texto",
          "descrição": "Obtém a largura em pixels que um texto ocupa para ser desenhado na tela\n",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "a largura do texto"
          },
          "parâmetros": [
            {
              "nome": "texto",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "o texto que será mensurado"
            }
          ]
        },
        {
          "nome": "liberar_imagem",
          "descrição": "Libera a memória utilizada por uma imagem que tenha sido previamente carregada",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço de memória da imagem"
            }
          ]
        },
        {
          "nome": "limpar",
          "descrição": "limpa o desenho do ambiente e gráfico e preenche o fundo com a cor atual",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": []
        },
        {
          "nome": "minimizar_janela",
          "descrição": "Minimiza a janela do ambiente gráfico, como se o usuário tivesse clicado no botão 'Minimizar' da janela",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": []
        },
        {
          "nome": "obter_cor_pixel",
          "descrição": "Esta função permite obter uma cor em um pixel específico de uma imagem previamente carregada no ambiente gráfico com a função carregar_imagem().",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "cor RGB do pixel"
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço de memória da imagem que será transformada"
            },
            {
              "nome": "x",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "coluna do pixel no bitmap"
            },
            {
              "nome": "y",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "linha do pixel no bitmap"
            }
          ]
        },
        {
          "nome": "obter_intervalo_gif",
          "descrição": "Obtém o intervalo entre os quadros de um gif",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "intervalo em milissegundos do quadro atual"
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço de memória do gif"
            }
          ]
        },
        {
          "nome": "obter_numero_quadro_atual_gif",
          "descrição": "Obtém o número do quadro atual de um gif",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "o número do quadro atual que o gif está"
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço de memória do gif"
            }
          ]
        },
        {
          "nome": "obter_numero_quadros_gif",
          "descrição": "Obtém o numero de quadros de um gif",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "o número de quadros que o gif possui"
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço de memória do gif"
            }
          ]
        },
        {
          "nome": "obter_quadro_gif",
          "descrição": "Obtém determinado quadro de um gif",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "imagem do quadro pedido por parâmetro"
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço de memória do gif"
            },
            {
              "nome": "quadro",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o quadro que deseja obter"
            }
          ]
        },
        {
          "nome": "obter_RGB",
          "descrição": "Esta função permite obter um canal de uma cor.",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "cor RGB do pixel"
          },
          "parâmetros": [
            {
              "nome": "cor",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "cor que será extraido o canal"
            },
            {
              "nome": "canal",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "canal R, G ou B"
            }
          ]
        },
        {
          "nome": "ocultar_borda_janela",
          "descrição": "Oculta a borda da janela do modo gráfico, fazendo com que somente o conteúdo da janela seja exibido",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": []
        },
        {
          "nome": "proximo_frame_gif",
          "descrição": "Define a imagem a ser desenhada do gif como o próximo frame",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço de memória no qual o gif foi carregado"
            }
          ]
        },
        {
          "nome": "redimensionar_imagem",
          "descrição": "Esta função permite redimensionar uma imagem previamente carregada no ambiente gráfico com a função carregar_imagem().\nCaso um dos parâmetros de dimensão seja 0, o outro parametro será proporcional ao dado\n",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "o endereço de memória da nova imagem"
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço de memória da imagem que será transformada"
            },
            {
              "nome": "largura",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "a largura desejada da imagem"
            },
            {
              "nome": "altura",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "a altura desejada da imagem"
            },
            {
              "nome": "manter_qualidade",
              "tipo": {
                "primitivo": "logico"
              },
              "descrição": "define se a qualidade da imagem deve ser mantida ao redimensionar"
            }
          ]
        },
        {
          "nome": "renderizar",
          "descrição": "Quando uma função de desenho da biblioteca é chamada, o desenho não é realizado imediatamente na tela, mas sim, em uma área reservada da memória. Isto é feito com o objetivo de aumentar o desempenho do programa e minimizar outros problemas. Esta técnica é chamada de **Back Buffer** ou **Double Buffer**.\n\nA função renderizar, faz com que os desenhos existentes no **Back Buffer** sejam desenhados na tela.\n\nEsta função deve ser chamada sempre após todas as outras funções de desenho, para garantir que todos os desenhos sejam exibidos\n",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": [],
          "referência": "http://en.wikipedia.org/wiki/Multiple_buffering#Double_buffering_in_computer_graphics"
        },
        {
          "nome": "renderizar_imagem",
          "descrição": "Esta função cria uma nova imagem em memória e renderiza todos os desenhos do ambiente gráfico nesta nova imagem ao invés de\nrenderizá-los na tela\n",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "o endereço de memória da nova imagem"
          },
          "parâmetros": [
            {
              "nome": "largura",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "a largura da nova imagem"
            },
            {
              "nome": "altura",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "a altura da nova imagem"
            }
          ]
        },
        {
          "nome": "restaurar_janela",
          "descrição": "Restaura a janela do ambiente gráfico, como se o usuário tivesse clicado no ícone do programa na barra de tarefas do Sistema Operacional",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": []
        },
        {
          "nome": "sair_modo_tela_cheia",
          "descrição": "Faz com que a janela gráfica seja redimensionada para o tamanho que possuía antes de entrar no modo de tela cheia",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": []
        },
        {
          "nome": "salvar_imagem",
          "descrição": "Salva uma imagem",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço de memória da imagem a ser desenhada"
            },
            {
              "nome": "caminho",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "lugar onde a imagem deverá ser salva"
            }
          ]
        },
        {
          "nome": "transformar_imagem",
          "descrição": "Esta função permite transformar uma imagem previamente carregada no ambiente gráfico com a função carregar_imagem().\nAs transformações possíveis são: espelhamento, rotação e remoção de cor.\n\nO espelhamento permite inverter a imagem tanto na direção horizontal quanto na direção vertical.\n\nA rotação, permite girar e inclinar a imagem em um ângulo de 360 graus.\n\nA remoção de cor, permite escolher uma cor da imagem e torná-la transparente.\n\nEsta função cria uma cópia da imagem original antes de aplicar as transformações, portanto, a imagem original não é perdida ao realizar a transformação e a nova imagem é alocada em outro endereço de memória\n",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "o endereço de memória da nova imagem"
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço de memória da imagem que será transformada"
            },
            {
              "nome": "espelhamento_horizontal",
              "tipo": {
                "primitivo": "logico"
              },
              "descrição": "define se a imagem será invertida (espelhada) na direção horizontal"
            },
            {
              "nome": "espelhamento_vertical",
              "tipo": {
                "primitivo": "logico"
              },
              "descrição": "define se a imagem será invertida (espelhada) na direção vertical"
            },
            {
              "nome": "rotacao",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "define em quantos graus a imagem será rotacionada. Se o valor 0 for informado, a imagem não será rotacionada.\nÉ importante notar que, ao rotacionar a imagem, as suas dimensões (largura e altura) poderão se alterar\n"
            },
            {
              "nome": "cor_transparente",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "define a cor que será removida da imagem, ou seja, que irá se tornar transparente. Se o valor 0 for informado, nenhuma cor será removida"
            }
          ]
        },
        {
          "nome": "transformar_porcao_imagem",
          "descrição": "Esta função permite transformar uma porção de uma imagem previamente carregada no ambiente gráfico com a função carregar_imagem().\nAs transformações possíveis são: espelhamento, rotação e remoção de cor.\n\nO espelhamento permite inverter uma porção da imagem tanto na direção horizontal quanto na direção vertical.\n\nA rotação, permite girar e inclinar uma porção da imagem em um ângulo de 360 graus.\n\nA remoção de cor, permite escolher uma cor da imagem e torná-la transparente.\n\nEsta função copia uma porção da imagem original antes de aplicar as transformações, portanto, a imagem original não é perdida ao realizar a transformação e a nova imagem é alocada em outro endereço de memória\n",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "o endereço de memória da nova imagem"
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço de memória da imagem que será transformada"
            },
            {
              "nome": "x",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "a posição (distância) no eixo horizontal a partir da qual a imagem será transformada"
            },
            {
              "nome": "y",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "a posição (distância) no eixo vertical a partir da qual a imagem será transformada"
            },
            {
              "nome": "largura",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "a largura da porção da imagem a ser transformada"
            },
            {
              "nome": "altura",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "a altura da porção da imagem a ser transformada"
            },
            {
              "nome": "espelhamento_horizontal",
              "tipo": {
                "primitivo": "logico"
              },
              "descrição": "define se a imagem será invertida (espelhada) na direção horizontal"
            },
            {
              "nome": "espelhamento_vertical",
              "tipo": {
                "primitivo": "logico"
              },
              "descrição": "define se a imagem será invertida (espelhada) na direção vertical"
            },
            {
              "nome": "rotacao",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "define em quantos graus a imagem será rotacionada. Se o valor 0 for informado, a imagem não será rotacionada.\nÉ importante notar que, ao rotacionar a imagem, as suas dimensões (largura e altura) poderão se alterar\n"
            },
            {
              "nome": "cor_transparente",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "define a cor que será removida da imagem, ou seja, que irá se tornar transparente. Se o valor 0 for informado, nenhuma cor será removida"
            }
          ]
        }
      ]
    },
    {
      "nome": "Internet",
      "descrição": "Esta biblioteca contém diversas funções de conexão com a web",
      "constantes": [],
      "funções": [
        {
          "nome": "baixar_imagem",
          "descrição": "Obtém recursos de uma página web",
          "retorno": {
            "tipo": {
              "primitivo": "cadeia"
            },
            "descrição": "O tipo da Imagem"
          },
          "parâmetros": [
            {
              "nome": "endereço",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "o endereço de onde o conteúdo deverá ser obtido"
            },
            {
              "nome": "caminho",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "o caminho de onde o conteúdo deverá ser salvo"
            }
          ]
        },
        {
          "nome": "definir_tempo_limite",
          "descrição": "Define um valor de tempo limite de espera",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": [
            {
              "nome": "tempo",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o tempo limite de espera em milisegundos"
            }
          ]
        },
        {
          "nome": "endereco_disponivel",
          "descrição": "Verifica a disponibilidade atual de algum endereço",
          "retorno": {
            "tipo": {
              "primitivo": "logico"
            },
            "descrição": "`verdadeiro` se o endereço estiver disponível no momento, `falso` se não\n"
          },
          "parâmetros": [
            {
              "nome": "endereço",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "o endereço de onde o conteúdo deverá ser obtido"
            }
          ]
        },
        {
          "nome": "obter_texto",
          "descrição": "Obtém o conteúdo de uma página web",
          "retorno": {
            "tipo": {
              "primitivo": "cadeia"
            },
            "descrição": "O conteúdo de uma página web"
          },
          "parâmetros": [
            {
              "nome": "caminho",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "o caminho de onde o conteúdo deverá ser obtido"
            }
          ]
        }
      ]
    },
    {
      "nome": "Matematica",
      "descrição": "Esta biblioteca contém uma série das funções matemáticas mais comuns",
      "constantes": [
        {
          "nome": "PI",
          "valor": 3.141592653589793,
          "descrição": "Constante matemática que representa a relação entre o perímetro de uma circunferência e seu diâmetro, em outras palavras: perimetro/diâmetro\n",
          "tipo": {
            "primitivo": "real"
          },
          "referência": "https://pt.wikipedia.org/wiki/Pi"
        }
      ],
      "funções": [
        {
          "nome": "arredondar",
          "descrição": "Arredonda um número `real` para o número de casas decimais informadas. Quando o último dígito for maior ou igual a 5, o número será arredondado para cima, quando o último dígito for menor que 5, o número será arredondado para baixo",
          "retorno": {
            "tipo": {
              "primitivo": "real"
            },
            "descrição": "o número arredondado"
          },
          "parâmetros": [
            {
              "nome": "numero",
              "tipo": {
                "primitivo": "real"
              },
              "descrição": "o número que será arredondado"
            },
            {
              "nome": "casas",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o número de casas decimais após o arredondamento"
            }
          ],
          "referência": "https://pt.wikipedia.org/wiki/Arredondamento"
        },
        {
          "nome": "cosseno",
          "descrição": "Calcula o cosseno do `ângulo` informado",
          "retorno": {
            "tipo": {
              "primitivo": "real"
            },
            "descrição": "o cosseno do `ângulo` informado"
          },
          "parâmetros": [
            {
              "nome": "angulo",
              "tipo": {
                "primitivo": "real"
              },
              "descrição": "o ângulo para o qual será calculado o cosseno"
            }
          ],
          "referência": "https://pt.wikipedia.org/wiki/Cosseno"
        },
        {
          "nome": "logaritmo",
          "descrição": "Calcula o logaritmo de um número para uma determinada base",
          "retorno": {
            "tipo": {
              "primitivo": "real"
            },
            "descrição": "o logaritmo"
          },
          "parâmetros": [
            {
              "nome": "numero",
              "tipo": {
                "primitivo": "real"
              },
              "descrição": "o número resultante da exponenciação"
            },
            {
              "nome": "base",
              "tipo": {
                "primitivo": "real"
              },
              "descrição": "a base da exponenciação"
            }
          ],
          "referência": "https://pt.wikipedia.org/wiki/Logaritmo"
        },
        {
          "nome": "maior_numero",
          "descrição": "Identifica o maior número entre os números informados",
          "retorno": {
            "tipo": {
              "primitivo": "real"
            },
            "descrição": "o maior número"
          },
          "parâmetros": [
            {
              "nome": "numeroA",
              "tipo": {
                "primitivo": "real"
              },
              "descrição": "um número qualquer"
            },
            {
              "nome": "numeroB",
              "tipo": {
                "primitivo": "real"
              },
              "descrição": "um número qualquer"
            }
          ]
        },
        {
          "nome": "menor_numero",
          "descrição": "Identifica o menor número entre os números informados",
          "retorno": {
            "tipo": {
              "primitivo": "real"
            },
            "descrição": "o menor número"
          },
          "parâmetros": [
            {
              "nome": "numeroA",
              "tipo": {
                "primitivo": "real"
              },
              "descrição": "um número qualquer"
            },
            {
              "nome": "numeroB",
              "tipo": {
                "primitivo": "real"
              },
              "descrição": "um número qualquer"
            }
          ]
        },
        {
          "nome": "potencia",
          "descrição": "Realiza uma exponenciação através da multiplicação da base por ela mesma tantas vezes quanto indicar o expoente",
          "retorno": {
            "tipo": {
              "primitivo": "real"
            },
            "descrição": "A exponenciação da base pelo expoente"
          },
          "parâmetros": [
            {
              "nome": "base",
              "tipo": {
                "primitivo": "real"
              },
              "descrição": "o número que será multiplicado"
            },
            {
              "nome": "expoente",
              "tipo": {
                "primitivo": "real"
              },
              "descrição": "o número de vezes que a base será multiplicada"
            }
          ],
          "referência": "https://pt.wikipedia.org/wiki/Exponencia%C3%A7%C3%A3o"
        },
        {
          "nome": "raiz",
          "descrição": "Realiza a radiciação (extrai a raiz) de um número por um determinado índice",
          "retorno": {
            "tipo": {
              "primitivo": "real"
            },
            "descrição": "a raiz do número informado"
          },
          "parâmetros": [
            {
              "nome": "radicando",
              "tipo": {
                "primitivo": "real"
              },
              "descrição": "o número do qual será extraída a raíz"
            },
            {
              "nome": "indice",
              "tipo": {
                "primitivo": "real"
              },
              "descrição": "indica o grau da radiciação. Quando o indice é 2 a raíz é quadrada, quando o indice é 3 a raíz é cúbica, e assim por diante"
            }
          ],
          "referência": "https://pt.wikipedia.org/wiki/Radicia%C3%A7%C3%A3o"
        },
        {
          "nome": "seno",
          "descrição": "Calcula o seno do **ângulo** informado",
          "retorno": {
            "tipo": {
              "primitivo": "real"
            },
            "descrição": "o seno do `ângulo` informado"
          },
          "parâmetros": [
            {
              "nome": "angulo",
              "tipo": {
                "primitivo": "real"
              },
              "descrição": "o ângulo para o qual será calculado o seno"
            }
          ],
          "referência": "https://pt.wikipedia.org/wiki/Seno"
        },
        {
          "nome": "tangente",
          "descrição": "Calcula a tangente do `ângulo` informado",
          "retorno": {
            "tipo": {
              "primitivo": "real"
            },
            "descrição": "a tangente do `ângulo` informado"
          },
          "parâmetros": [
            {
              "nome": "angulo",
              "tipo": {
                "primitivo": "real"
              },
              "descrição": "o ângulo para o qual será calculada a tangente"
            }
          ],
          "referência": "https://pt.wikipedia.org/wiki/Tangente"
        },
        {
          "nome": "valor_absoluto",
          "descrição": "Calcula o valor absoluto do `número` informado",
          "retorno": {
            "tipo": {
              "primitivo": "real"
            },
            "descrição": "o valor absoluto do `número` informado"
          },
          "parâmetros": [
            {
              "nome": "numero",
              "tipo": {
                "primitivo": "real"
              },
              "descrição": "o número para o qual será calculado o valor absoluto"
            }
          ],
          "referência": "https://pt.wikipedia.org/wiki/Fun%C3%A7%C3%A3o_modular"
        }
      ]
    },
    {
      "nome": "Mouse",
      "descrição": "Esta biblioteca contém um conjunto de funções para manipular a entrada de dados através do mouse do computador. **IMPORTANTE:** Esta biblioteca não funciona no console de entrada e saída de dados do Portugol Studio, ela só funciona com a biblioteca Graficos, se o modo gráfico estiver iniciado.",
      "constantes": [
        {
          "nome": "BOTAO_DIREITO",
          "valor": 1,
          "descrição": "Código numérico do botão direito do mouse",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "BOTAO_ESQUERDO",
          "valor": 0,
          "descrição": "Código numérico do botão esquerdo do mouse",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "BOTAO_MEIO",
          "valor": 2,
          "descrição": "Código numérico do botão do meio do mouse",
          "tipo": {
            "primitivo": "inteiro"
          }
        }
      ],
      "funções": [
        {
          "nome": "algum_botao_pressionado",
          "descrição": "Testa se existe algum botão do mouse pressionado neste instante",
          "retorno": {
            "tipo": {
              "primitivo": "logico"
            },
            "descrição": "o resultado do teste. `verdadeiro` se houver um botão do mouse pressionado no momento do teste. Caso contrário, retorna `falso`"
          },
          "parâmetros": []
        },
        {
          "nome": "botao_pressionado",
          "descrição": "Testa se um determinado `botão` do mouse está pressionado neste instante",
          "retorno": {
            "tipo": {
              "primitivo": "logico"
            },
            "descrição": "o resultado do teste. `verdadeiro` se o `botão` estiver pressionado no momento do teste. Caso contrário, retorna `falso`"
          },
          "parâmetros": [
            {
              "nome": "botao",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o código do botão que será testado"
            }
          ]
        },
        {
          "nome": "exibir_cursor",
          "descrição": "Exibe novamente o cursor do mouse caso ele esteja oculto",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": []
        },
        {
          "nome": "ler_botao",
          "descrição": "Aguarda até que um botão seja clicado (isto é, foi pressionado e depois solto), e captura o seu código",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "o código do botão lido"
          },
          "parâmetros": []
        },
        {
          "nome": "ocultar_cursor",
          "descrição": "Oculta o cursor do mouse",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": []
        },
        {
          "nome": "posicao_x",
          "descrição": "Obtém a coordenada X do mouse neste instante",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "a coordenada X do mouse neste instante"
          },
          "parâmetros": []
        },
        {
          "nome": "posicao_y",
          "descrição": "Obtém a coordenada Y do mouse neste instante",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "a coordenada Y do mouse neste instante"
          },
          "parâmetros": []
        }
      ]
    },
    {
      "nome": "Objetos",
      "descrição": "Esta biblioteca contém uma série de funções para criar e trabalhar com objetos",
      "constantes": [
        {
          "nome": "TIPO_CADEIA",
          "valor": 2,
          "descrição": "Constante para definir o tipo cadeia",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TIPO_CARACTER",
          "valor": 3,
          "descrição": "Constante para definir o tipo caracter",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TIPO_INTEIRO",
          "valor": 1,
          "descrição": "Constante para definir o tipo inteiro",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TIPO_LOGICO",
          "valor": 5,
          "descrição": "Constante para definir o tipo logico",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TIPO_OBJETO",
          "valor": 6,
          "descrição": "Constante para definir o tipo objeto",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TIPO_REAL",
          "valor": 4,
          "descrição": "Constante para definir o tipo real",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TIPO_VETOR",
          "valor": 7,
          "descrição": "Constante para definir o tipo vetor",
          "tipo": {
            "primitivo": "inteiro"
          }
        }
      ],
      "funções": [
        {
          "nome": "atribuir_propriedade",
          "descrição": "Realiza a atribuição de uma propriedade do objeto no endereço informado",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço onde o objeto foi armazenado"
            },
            {
              "nome": "propriedade",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "a descrição da propriedade que terá o valor alterado"
            },
            {
              "nome": "valor",
              "tipo": {
                "primitivo": "*"
              },
              "descrição": "o valor que será atribuído para a propriedade"
            }
          ]
        },
        {
          "nome": "contem_propriedade",
          "descrição": "Identifica se o objeto contém ou não a propriedade informada",
          "retorno": {
            "tipo": {
              "primitivo": "logico"
            },
            "descrição": "`verdadeiro` se o objeto contém a propriedade informada. Caso contrário, retorna `falso`\n"
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço onde o objeto foi armazenado"
            },
            {
              "nome": "propriedade",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "propriedade utilizada para verificação"
            }
          ]
        },
        {
          "nome": "criar_objeto",
          "descrição": "Realiza a criação de um objeto vazio em memória",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "O endereço de memória no qual o objeto foi carregado"
          },
          "parâmetros": []
        },
        {
          "nome": "criar_objeto_via_json",
          "descrição": "Realiza a criação de um objeto a partir de uma cadeia no formato JSON (JavaScript Object Notation)",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "O endereço de memória no qual o objeto foi carregado"
          },
          "parâmetros": [
            {
              "nome": "json",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "texto no formato JSON para criar o objeto"
            }
          ],
          "referência": "https://pt.wikipedia.org/wiki/JSON"
        },
        {
          "nome": "criar_objeto_via_xml",
          "descrição": "Realiza a criação de um objeto a partir de uma cadeia no formato XML (eXtensible Markup Language)",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "O endereço de memória no qual o objeto foi carregado"
          },
          "parâmetros": [
            {
              "nome": "xml",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "texto no formato XML para criar o objeto"
            }
          ],
          "referência": "https://pt.wikipedia.org/wiki/XML"
        },
        {
          "nome": "liberar",
          "descrição": "Libera todos os objetos previamente armazenados na memória",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": []
        },
        {
          "nome": "liberar_objeto",
          "descrição": "Libera o objeto do endereço informado da memória",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço onde o objeto foi armazenado"
            }
          ]
        },
        {
          "nome": "obter_json",
          "descrição": "Obtém a cadeia que representa o objeto inteiro no formato JSON (JavaScript Object Notation)",
          "retorno": {
            "tipo": {
              "primitivo": "cadeia"
            },
            "descrição": "a cadeia que representa o objeto inteiro no formato JSON"
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço onde o objeto foi armazenado"
            }
          ],
          "referência": "https://pt.wikipedia.org/wiki/JSON"
        },
        {
          "nome": "obter_propriedade_tipo_cadeia",
          "descrição": "Obtém o valor de uma propriedade do tipo cadeia no objeto do endereço informado",
          "retorno": {
            "tipo": {
              "primitivo": "cadeia"
            },
            "descrição": "o valor da propriedade informada"
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço onde o objeto foi armazenado"
            },
            {
              "nome": "propriedade",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "a descrição da propriedade que terá o valor obtido"
            }
          ]
        },
        {
          "nome": "obter_propriedade_tipo_cadeia_em_vetor",
          "descrição": "Obtém o valor de um vetor armazenado no objeto do endereço informado",
          "retorno": {
            "tipo": {
              "primitivo": "cadeia"
            },
            "descrição": "o valor da propriedade informada"
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço onde o objeto foi armazenado"
            },
            {
              "nome": "propriedade",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "a descrição da propriedade que terá o valor obtido"
            },
            {
              "nome": "indice",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o índice do elemento do vetor que será obtido"
            }
          ]
        },
        {
          "nome": "obter_propriedade_tipo_caracter",
          "descrição": "Obtém o valor de uma propriedade do tipo caracter no objeto do endereço informado",
          "retorno": {
            "tipo": {
              "primitivo": "caracter"
            },
            "descrição": "o valor da propriedade informada"
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço onde o objeto foi armazenado"
            },
            {
              "nome": "propriedade",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "a descrição da propriedade que terá o valor obtido"
            }
          ]
        },
        {
          "nome": "obter_propriedade_tipo_caracter_em_vetor",
          "descrição": "Obtém o valor de um vetor armazenado no objeto do endereço informado",
          "retorno": {
            "tipo": {
              "primitivo": "caracter"
            },
            "descrição": "o valor da propriedade informada"
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço onde o objeto foi armazenado"
            },
            {
              "nome": "propriedade",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "a descrição da propriedade que terá o valor obtido"
            },
            {
              "nome": "indice",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o índice do elemento do vetor que será obtido"
            }
          ]
        },
        {
          "nome": "obter_propriedade_tipo_inteiro",
          "descrição": "Obtém o valor de uma propriedade do tipo inteiro no objeto do endereço informado",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "o valor da propriedade informada"
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço onde o objeto foi armazenado"
            },
            {
              "nome": "propriedade",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "a descrição da propriedade que terá o valor obtido"
            }
          ]
        },
        {
          "nome": "obter_propriedade_tipo_inteiro_em_vetor",
          "descrição": "Obtém o valor de um vetor armazenado no objeto do endereço informado",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "o valor da propriedade informada"
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço onde o objeto foi armazenado"
            },
            {
              "nome": "propriedade",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "a descrição da propriedade que terá o valor obtido"
            },
            {
              "nome": "indice",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o índice do elemento do vetor que será obtido"
            }
          ]
        },
        {
          "nome": "obter_propriedade_tipo_logico",
          "descrição": "Obtém o valor de uma propriedade do tipo logico no objeto do endereço informado",
          "retorno": {
            "tipo": {
              "primitivo": "logico"
            },
            "descrição": "o valor da propriedade informada"
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço onde o objeto foi armazenado"
            },
            {
              "nome": "propriedade",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "a descrição da propriedade que terá o valor obtido"
            }
          ]
        },
        {
          "nome": "obter_propriedade_tipo_logico_em_vetor",
          "descrição": "Obtém o valor de um vetor armazenado no objeto do endereço informado",
          "retorno": {
            "tipo": {
              "primitivo": "logico"
            },
            "descrição": "o valor da propriedade informada"
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço onde o objeto foi armazenado"
            },
            {
              "nome": "propriedade",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "a descrição da propriedade que terá o valor obtido"
            },
            {
              "nome": "indice",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o índice do elemento do vetor que será obtido"
            }
          ]
        },
        {
          "nome": "obter_propriedade_tipo_objeto",
          "descrição": "Obtém o valor de uma propriedade do tipo objeto no objeto do endereço informado",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "o valor da propriedade informada"
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço onde o objeto foi armazenado"
            },
            {
              "nome": "propriedade",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "a descrição da propriedade que terá o valor obtido"
            }
          ]
        },
        {
          "nome": "obter_propriedade_tipo_objeto_em_vetor",
          "descrição": "Obtém o valor de um vetor armazenado no objeto do endereço informado",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "o valor da propriedade informada"
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço onde o objeto foi armazenado"
            },
            {
              "nome": "propriedade",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "a descrição da propriedade que terá o valor obtido"
            },
            {
              "nome": "indice",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o índice do elemento do vetor que será obtido"
            }
          ]
        },
        {
          "nome": "obter_propriedade_tipo_real",
          "descrição": "Obtém o valor de uma propriedade do tipo real no objeto do endereço informado",
          "retorno": {
            "tipo": {
              "primitivo": "real"
            },
            "descrição": "o valor da propriedade informada"
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço onde o objeto foi armazenado"
            },
            {
              "nome": "propriedade",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "a descrição da propriedade que terá o valor obtido"
            }
          ]
        },
        {
          "nome": "obter_propriedade_tipo_real_em_vetor",
          "descrição": "Obtém o valor de um vetor armazenado no objeto do endereço informado",
          "retorno": {
            "tipo": {
              "primitivo": "real"
            },
            "descrição": "o valor da propriedade informada"
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço onde o objeto foi armazenado"
            },
            {
              "nome": "propriedade",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "a descrição da propriedade que terá o valor obtido"
            },
            {
              "nome": "indice",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o índice do elemento do vetor que será obtido"
            }
          ]
        },
        {
          "nome": "obter_tamanho_vetor_propriedade",
          "descrição": "Obtém o tamanho de um vetor armazenado no objeto do endereço informado",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "o valor da propriedade informada"
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço onde o objeto foi armazenado"
            },
            {
              "nome": "propriedade",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "a descrição da propriedade que terá o valor obtido"
            }
          ]
        },
        {
          "nome": "tipo_propriedade",
          "descrição": "Identifica o tipo da propriedade informada",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "o tipo da propriedade informada"
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço onde o objeto foi armazenado"
            },
            {
              "nome": "propriedade",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "propriedade utilizada para verificação"
            }
          ]
        }
      ]
    },
    {
      "nome": "ServicosWeb",
      "descrição": "Esta biblioteca contém uma série de funções para trabalhar com serviços web",
      "constantes": [
        {
          "nome": "ATUALIZAR",
          "valor": 3,
          "descrição": "Constante para definir o método HTTP PUT",
          "tipo": {
            "primitivo": "inteiro"
          },
          "referência": "https://pt.wikipedia.org/wiki/Hypertext_Transfer_Protocol#PUT"
        },
        {
          "nome": "EXCLUIR",
          "valor": 4,
          "descrição": "Constante para definir o método HTTP DELETE",
          "tipo": {
            "primitivo": "inteiro"
          },
          "referência": "https://pt.wikipedia.org/wiki/Hypertext_Transfer_Protocol#DELETE"
        },
        {
          "nome": "OBTER",
          "valor": 1,
          "descrição": "Constante para definir o método HTTP GET",
          "tipo": {
            "primitivo": "inteiro"
          },
          "referência": "https://pt.wikipedia.org/wiki/Hypertext_Transfer_Protocol#GET"
        },
        {
          "nome": "PUBLICAR",
          "valor": 2,
          "descrição": "Constante para definir o método HTTP POST",
          "tipo": {
            "primitivo": "inteiro"
          },
          "referência": "https://pt.wikipedia.org/wiki/Hypertext_Transfer_Protocol#POST"
        }
      ],
      "funções": [
        {
          "nome": "abrir_conexao",
          "descrição": "Abre uma conexão customizável com o endereço especificado",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "endereço para o qual a requisição será feita"
            }
          ]
        },
        {
          "nome": "adicionar_cabecalho",
          "descrição": "Adiciona um cabeçalho na conexão customizável, para utilizar esta função é necessário abrir uma conexão antes",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": [
            {
              "nome": "chave",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "chave do cabeçalho que será incluído na conexão"
            },
            {
              "nome": "valor",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "valor do cabeçalho que será incluído na conexão"
            }
          ],
          "referência": "https://pt.wikipedia.org/wiki/Lista_de_campos_de_cabe%C3%A7alho_HTTP"
        },
        {
          "nome": "adicionar_parametros",
          "descrição": "Adiciona um objeto no formato JSON na conexão customizável, para utilizar esta função é necessário abrir uma conexão antes. Esta função deve ser utilizada para informar os parâmetros dos métodos HTTP POST e PUT",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": [
            {
              "nome": "objeto",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "objeto no formato JSON passado por parâmetro no corpo da requisição"
            }
          ],
          "referência": "https://pt.wikipedia.org/wiki/Hypertext_Transfer_Protocol"
        },
        {
          "nome": "atualizar_dados",
          "descrição": "Realiza uma chamada HTTP PUT para o endereço especificado, retornando uma cadeia no formato JSON como resposta",
          "retorno": {
            "tipo": {
              "primitivo": "cadeia"
            },
            "descrição": "a cadeia JSON contendo a resposta da requisição"
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "endereço para o qual a requisição será feita"
            },
            {
              "nome": "objeto",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "objeto no formato JSON passado por parâmetro no corpo da requisição"
            }
          ],
          "referência": "https://pt.wikipedia.org/wiki/Hypertext_Transfer_Protocol#PUT"
        },
        {
          "nome": "excluir_dados",
          "descrição": "Realiza uma chamada HTTP DELETE para o endereço especificado, retornando uma cadeia no formato JSON como resposta",
          "retorno": {
            "tipo": {
              "primitivo": "cadeia"
            },
            "descrição": "a cadeia JSON contendo a resposta da requisição"
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "endereço para o qual a requisição será feita"
            }
          ],
          "referência": "https://pt.wikipedia.org/wiki/Hypertext_Transfer_Protocol#DELETE"
        },
        {
          "nome": "fazer_requisicao",
          "descrição": "Realiza uma chamada HTTP através de uma conexão customizada, retornando uma cadeia no formato JSON como resposta",
          "retorno": {
            "tipo": {
              "primitivo": "cadeia"
            },
            "descrição": "a cadeia JSON contendo a resposta da requisição"
          },
          "parâmetros": [
            {
              "nome": "metodoHttp",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "Método HTTP que será utilizado nesta execução (GET, POST, PUT ou DELETE)"
            }
          ],
          "referência": "https://pt.wikipedia.org/wiki/Hypertext_Transfer_Protocol"
        },
        {
          "nome": "obter_dados",
          "descrição": "Realiza uma chamada HTTP GET para o endereço especificado, retornando uma cadeia no formato JSON como resposta",
          "retorno": {
            "tipo": {
              "primitivo": "cadeia"
            },
            "descrição": "a cadeia JSON contendo a resposta da requisição"
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "endereço para o qual a requisição será feita"
            }
          ],
          "referência": "https://pt.wikipedia.org/wiki/Hypertext_Transfer_Protocol#GET"
        },
        {
          "nome": "publicar_dados",
          "descrição": "Realiza uma chamada HTTP POST para o endereço especificado, retornando uma cadeia no formato JSON como resposta",
          "retorno": {
            "tipo": {
              "primitivo": "cadeia"
            },
            "descrição": "a cadeia JSON contendo a resposta da requisição"
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "endereço para o qual a requisição será feita"
            },
            {
              "nome": "objeto",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "objeto no formato JSON passado por parâmetro no corpo da requisição"
            }
          ],
          "referência": "https://pt.wikipedia.org/wiki/Hypertext_Transfer_Protocol#POST"
        }
      ]
    },
    {
      "nome": "Sons",
      "descrição": "Esta biblioteca contém funções que permitem reproduzir sons dentro de um programa. No momento, somente o formato MP3 é suportado.",
      "constantes": [],
      "funções": [
        {
          "nome": "carregar_som",
          "descrição": "Carrega um som na memória para ser reproduzido mais tarde",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "o endereço de memória no qual o som foi carregado"
          },
          "parâmetros": [
            {
              "nome": "caminho_som",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "o caminho para o arquivo de som no sistema de arquivos"
            }
          ]
        },
        {
          "nome": "definir_posicao_atual_musica",
          "descrição": "Define a posição atual da música em milisegundos",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço de memória da reprodução que se quer alterar o volume"
            },
            {
              "nome": "milissegundos",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o tempo em milissegundos que deseja colocar a musica"
            }
          ]
        },
        {
          "nome": "definir_volume",
          "descrição": "Define o volume geral",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": [
            {
              "nome": "volume",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "O novo volume geral (entre 0 e 100)"
            }
          ]
        },
        {
          "nome": "definir_volume_reproducao",
          "descrição": "Define um novo volume para um som que já está sendo executado",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço de memória da reprodução que se quer alterar o volume"
            },
            {
              "nome": "volume",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o novo volume entre 0 e 100"
            }
          ]
        },
        {
          "nome": "interromper_som",
          "descrição": "Interrompe uma reprodução específica de um som que está sendo executada no momento",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço de memória da reprodução que se quer interromper"
            }
          ]
        },
        {
          "nome": "liberar_som",
          "descrição": "Libera a memória utilizada por um som que tenha sido previamente carregado. Se o som estiver sendo reproduzido, todas as reproduções deste som serão interrompidas",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço de memória do som"
            }
          ]
        },
        {
          "nome": "obter_posicao_atual_musica",
          "descrição": "Obtêm a posição atual da música em milisegundos",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "o tempo atual em milissegundos do som"
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço de memória da reprodução que se quer alterar o volume"
            }
          ]
        },
        {
          "nome": "obter_tamanho_musica",
          "descrição": "Obtêm o tamanho da música em milisegundos",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "tempo total em milissegundos do som"
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço de memória da reprodução que se quer alterar o volume"
            }
          ]
        },
        {
          "nome": "obter_volume",
          "descrição": "Retorna o volume geral",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "Um valor do tipo inteiro entre 0 e 100 representando o volume geral atual."
          },
          "parâmetros": []
        },
        {
          "nome": "obter_volume_reproducao",
          "descrição": "Retornar o volume de uma reprodução de som",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "Um valor do tipo inteiro entre 0 e 100 representando o volume atual da reprodução. Caso a reprodução não exista ou já tenha sido finalizada o valor -1 será retornado"
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "O endereço de memória da reprodução que se quer obter o volume"
            }
          ]
        },
        {
          "nome": "pausar_som",
          "descrição": "Pausa uma reprodução específica de um som que está sendo executada no momento",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço de memória da reprodução que se quer interromper"
            }
          ]
        },
        {
          "nome": "reproduzir_som",
          "descrição": "Reproduz um som previamente carregado na memória. O som é reproduzido de forma assíncrona, ou seja, o som ficará reproduzindo no fundo, enquanto o programa executa as próximas instruções normalmente. Um mesmo som pode ser reproduzido várias vezes e pode se sobrepor a outros sons.",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "o endereço de memória da reprodução de som. Este endereço é utilizado quando se deseja interromper esta reprodução de som especificamente."
          },
          "parâmetros": [
            {
              "nome": "endereco",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o endereço de memória do som a ser reproduzido"
            },
            {
              "nome": "repetir",
              "tipo": {
                "primitivo": "logico"
              },
              "descrição": "determina se o som deve ficar repetindo até ser manualmente interrompido"
            }
          ]
        }
      ]
    },
    {
      "nome": "Teclado",
      "descrição": "Esta biblioteca contém um conjunto de funções para manipular a entrada de dados através do teclado do computador. **IMPORTANTE:** Esta biblioteca não funciona no console de entrada e saída de dados do Portugol Studio, ela só funciona com a biblioteca Graficos, se o modo gráfico estiver iniciado.",
      "constantes": [
        {
          "nome": "TECLA_0",
          "valor": 48,
          "descrição": "Código numérico da tecla '0' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_0_NUM",
          "valor": 96,
          "descrição": "Código numérico da tecla '0' no teclado numérico",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_1",
          "valor": 49,
          "descrição": "Código numérico da tecla '1' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_1_NUM",
          "valor": 97,
          "descrição": "Código numérico da tecla '1' no teclado numérico",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_2",
          "valor": 50,
          "descrição": "Código numérico da tecla '2' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_2_NUM",
          "valor": 98,
          "descrição": "Código numérico da tecla '2' no teclado numérico",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_3",
          "valor": 51,
          "descrição": "Código numérico da tecla '3' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_3_NUM",
          "valor": 99,
          "descrição": "Código numérico da tecla '3' no teclado numérico",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_4",
          "valor": 52,
          "descrição": "Código numérico da tecla '4' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_4_NUM",
          "valor": 100,
          "descrição": "Código numérico da tecla '4' no teclado numérico",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_5",
          "valor": 53,
          "descrição": "Código numérico da tecla '5' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_5_NUM",
          "valor": 101,
          "descrição": "Código numérico da tecla '5' no teclado numérico",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_6",
          "valor": 54,
          "descrição": "Código numérico da tecla '6' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_6_NUM",
          "valor": 102,
          "descrição": "Código numérico da tecla '6' no teclado numérico",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_7",
          "valor": 55,
          "descrição": "Código numérico da tecla '7' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_7_NUM",
          "valor": 103,
          "descrição": "Código numérico da tecla '7' no teclado numérico",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_8",
          "valor": 56,
          "descrição": "Código numérico da tecla '8' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_8_NUM",
          "valor": 104,
          "descrição": "Código numérico da tecla '8' no teclado numérico",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_9",
          "valor": 57,
          "descrição": "Código numérico da tecla '9' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_9_NUM",
          "valor": 105,
          "descrição": "Código numérico da tecla '9' no teclado numérico",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_A",
          "valor": 65,
          "descrição": "Código numérico da tecla 'A' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_ABRE_COLCHETES",
          "valor": 91,
          "descrição": "Código numérico da tecla '[' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_ADICAO",
          "valor": 107,
          "descrição": "Código numérico da tecla '+' no teclado numérico",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_AJUDA",
          "valor": 156,
          "descrição": "Código numérico da tecla AJUDA no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_ALT",
          "valor": 18,
          "descrição": "Código numérico da tecla ALT no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_B",
          "valor": 66,
          "descrição": "Código numérico da tecla 'B' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_BACKSPACE",
          "valor": 8,
          "descrição": "Código numérico da tecla BACKSPACE no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_BARRA",
          "valor": 47,
          "descrição": "Código numérico da tecla '/' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_BARRA_INVERTIDA",
          "valor": 92,
          "descrição": "Código numérico da tecla '\\' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_C",
          "valor": 67,
          "descrição": "Código numérico da tecla 'C' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_CANCELAR",
          "valor": 3,
          "descrição": "Código numérico da tecla CANCELAR no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_CAPS_LOCK",
          "valor": 20,
          "descrição": "Código numérico da tecla CAPSLOCK no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_CONTROL",
          "valor": 17,
          "descrição": "Código numérico da tecla CONTROL no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_D",
          "valor": 68,
          "descrição": "Código numérico da tecla 'D' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_DECIMAL",
          "valor": 110,
          "descrição": "Código numérico da tecla DECIMAL no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_DELETAR",
          "valor": 127,
          "descrição": "Código numérico da tecla DELETE no teclado numérico",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_DIVISAO",
          "valor": 111,
          "descrição": "Código numérico da tecla '/' no teclado numérico",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_E",
          "valor": 69,
          "descrição": "Código numérico da tecla 'E' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_END",
          "valor": 35,
          "descrição": "Código numérico da tecla END no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_ENTER",
          "valor": 10,
          "descrição": "Código numérico da tecla ENTER no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_ESC",
          "valor": 27,
          "descrição": "Código numérico da tecla ESC no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_ESPACO",
          "valor": 32,
          "descrição": "Código numérico da tecla ESPAÇO no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_F",
          "valor": 70,
          "descrição": "Código numérico da tecla 'F' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_F1",
          "valor": 112,
          "descrição": "Código numérico da tecla 'F1' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_F10",
          "valor": 121,
          "descrição": "Código numérico da tecla 'F10' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_F11",
          "valor": 122,
          "descrição": "Código numérico da tecla 'F11' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_F12",
          "valor": 123,
          "descrição": "Código numérico da tecla 'F12' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_F13",
          "valor": 61440,
          "descrição": "Código numérico da tecla 'F13' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_F14",
          "valor": 61441,
          "descrição": "Código numérico da tecla 'F14' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_F15",
          "valor": 61442,
          "descrição": "Código numérico da tecla 'F15' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_F16",
          "valor": 61443,
          "descrição": "Código numérico da tecla 'F16' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_F17",
          "valor": 61444,
          "descrição": "Código numérico da tecla 'F17' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_F18",
          "valor": 61445,
          "descrição": "Código numérico da tecla 'F18' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_F19",
          "valor": 61446,
          "descrição": "Código numérico da tecla 'F19' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_F2",
          "valor": 113,
          "descrição": "Código numérico da tecla 'F2' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_F20",
          "valor": 61447,
          "descrição": "Código numérico da tecla 'F20' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_F21",
          "valor": 61448,
          "descrição": "Código numérico da tecla 'F21' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_F22",
          "valor": 61449,
          "descrição": "Código numérico da tecla 'F22' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_F23",
          "valor": 61450,
          "descrição": "Código numérico da tecla 'F23' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_F24",
          "valor": 61451,
          "descrição": "Código numérico da tecla 'F24' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_F3",
          "valor": 114,
          "descrição": "Código numérico da tecla 'F3' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_F4",
          "valor": 115,
          "descrição": "Código numérico da tecla 'F4' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_F5",
          "valor": 116,
          "descrição": "Código numérico da tecla 'F5' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_F6",
          "valor": 117,
          "descrição": "Código numérico da tecla 'F6' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_F7",
          "valor": 118,
          "descrição": "Código numérico da tecla 'F7' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_F8",
          "valor": 119,
          "descrição": "Código numérico da tecla 'F8' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_F9",
          "valor": 120,
          "descrição": "Código numérico da tecla 'F9' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_FECHA_COLCHETES",
          "valor": 93,
          "descrição": "Código numérico da tecla ']' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_G",
          "valor": 71,
          "descrição": "Código numérico da tecla 'G' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_H",
          "valor": 72,
          "descrição": "Código numérico da tecla 'H' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_HOME",
          "valor": 36,
          "descrição": "Código numérico da tecla HOME no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_I",
          "valor": 73,
          "descrição": "Código numérico da tecla 'I' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_IGUAL",
          "valor": 61,
          "descrição": "Código numérico da tecla '=' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_INSERT",
          "valor": 155,
          "descrição": "Código numérico da tecla INSERT no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_J",
          "valor": 74,
          "descrição": "Código numérico da tecla 'J' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_K",
          "valor": 75,
          "descrição": "Código numérico da tecla 'K' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_L",
          "valor": 76,
          "descrição": "Código numérico da tecla 'L' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_LIMPAR",
          "valor": 12,
          "descrição": "Código numérico da tecla LIMPAR no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_M",
          "valor": 77,
          "descrição": "Código numérico da tecla 'M' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_MENOS",
          "valor": 45,
          "descrição": "Código numérico da tecla '-' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_MENU_CONTEXTO",
          "valor": 158,
          "descrição": "Código numérico da tecla MENU DE CONTEXTO no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_MULTIPLICACAO",
          "valor": 106,
          "descrição": "Código numérico da tecla '*' no teclado numérico",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_N",
          "valor": 78,
          "descrição": "Código numérico da tecla 'N' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_NUM_LOCK",
          "valor": 144,
          "descrição": "Código numérico da tecla NUM LOCK no teclado numérico",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_NUM_SEPARADOR_DECIMAL",
          "valor": 108,
          "descrição": "Código numérico da tecla de separador decimal (',' ou '.') no teclado numérico",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_O",
          "valor": 79,
          "descrição": "Código numérico da tecla 'O' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_P",
          "valor": 80,
          "descrição": "Código numérico da tecla 'P' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_PAGE_DOWN",
          "valor": 34,
          "descrição": "Código numérico da tecla PAGE DOWN no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_PAGE_UP",
          "valor": 33,
          "descrição": "Código numérico da tecla PAGE UP no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_PAUSE",
          "valor": 19,
          "descrição": "Código numérico da tecla PAUSE/BREAK no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_PONTO_FINAL",
          "valor": 46,
          "descrição": "Código numérico da tecla '.' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_PONTO_VIRGULA",
          "valor": 59,
          "descrição": "Código numérico da tecla ';' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_PRINTSCREEN",
          "valor": 154,
          "descrição": "Código numérico da tecla PRINT SCREEN no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_Q",
          "valor": 81,
          "descrição": "Código numérico da tecla 'Q' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_R",
          "valor": 82,
          "descrição": "Código numérico da tecla 'R' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_S",
          "valor": 83,
          "descrição": "Código numérico da tecla 'S' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_SCROLL_LOCK",
          "valor": 145,
          "descrição": "Código numérico da tecla SCROLL LOCK no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_SETA_ABAIXO",
          "valor": 40,
          "descrição": "Código numérico da tecla SETA ABAIXO no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_SETA_ACIMA",
          "valor": 38,
          "descrição": "Código numérico da tecla SETA ACIMA no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_SETA_DIREITA",
          "valor": 39,
          "descrição": "Código numérico da tecla SETA DIREITA no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_SETA_ESQUERDA",
          "valor": 37,
          "descrição": "Código numérico da tecla SETA ESQUERDA no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_SHIFT",
          "valor": 16,
          "descrição": "Código numérico da tecla SHIFT no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_SUBTRACAO",
          "valor": 109,
          "descrição": "Código numérico da tecla '-' no teclado numérico",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_T",
          "valor": 84,
          "descrição": "Código numérico da tecla 'T' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_TAB",
          "valor": 9,
          "descrição": "Código numérico da tecla TAB no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_U",
          "valor": 85,
          "descrição": "Código numérico da tecla 'U' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_V",
          "valor": 86,
          "descrição": "Código numérico da tecla 'V' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_VIRGULA",
          "valor": 44,
          "descrição": "Código numérico da tecla ',' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_W",
          "valor": 87,
          "descrição": "Código numérico da tecla 'W' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_WINDOWS",
          "valor": 157,
          "descrição": "Código numérico da tecla de acesso ao menu Iniciar no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_X",
          "valor": 88,
          "descrição": "Código numérico da tecla 'X' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_Y",
          "valor": 89,
          "descrição": "Código numérico da tecla 'Y' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        },
        {
          "nome": "TECLA_Z",
          "valor": 90,
          "descrição": "Código numérico da tecla 'Z' no teclado",
          "tipo": {
            "primitivo": "inteiro"
          }
        }
      ],
      "funções": [
        {
          "nome": "alguma_tecla_pressionada",
          "descrição": "Testa se existe alguma tecla pressionada neste instante",
          "retorno": {
            "tipo": {
              "primitivo": "logico"
            },
            "descrição": "o resultado do teste. `verdadeiro` se houver uma tecla pressionada no momento do teste. Caso contrário, retorna `falso`"
          },
          "parâmetros": []
        },
        {
          "nome": "caracter_tecla",
          "descrição": "Obtém o caracter correspondente a uma determinada `tecla`",
          "retorno": {
            "tipo": {
              "primitivo": "caracter"
            },
            "descrição": "o caracter correspondente à tecla"
          },
          "parâmetros": [
            {
              "nome": "tecla",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "a tecla para a qual se quer obter o caracter"
            }
          ]
        },
        {
          "nome": "ler_tecla",
          "descrição": "Aguarda até que uma tecla seja digitada, isto é, foi pressionada e depois solta, e captura o seu código",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "o código da tecla lida"
          },
          "parâmetros": []
        },
        {
          "nome": "tecla_pressionada",
          "descrição": "Testa se uma determinada `tecla` está pressionada neste instante",
          "retorno": {
            "tipo": {
              "primitivo": "logico"
            },
            "descrição": "o resultado do teste. `Verdadeiro` se a `tecla` estiver pressionada no momento do teste. Caso contrário, retorna `falso`"
          },
          "parâmetros": [
            {
              "nome": "tecla",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o código da tecla que será testada"
            }
          ]
        }
      ]
    },
    {
      "nome": "Texto",
      "descrição": "Esta biblioteca contém funções para manipulação de texto (dados do tipo `cadeia`)",
      "constantes": [],
      "funções": [
        {
          "nome": "caixa_alta",
          "descrição": "Transforma os caracteres de uma `cadeia` em caracteres maiúsculos",
          "retorno": {
            "tipo": {
              "primitivo": "cadeia"
            },
            "descrição": "a `cadeia` com os caracteres transformados"
          },
          "parâmetros": [
            {
              "nome": "cad",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "um valor qualquer do tipo `cadeia`"
            }
          ]
        },
        {
          "nome": "caixa_baixa",
          "descrição": "Transforma os caracteres de uma `cadeia` em caracteres minúsculos",
          "retorno": {
            "tipo": {
              "primitivo": "cadeia"
            },
            "descrição": "a `cadeia` com os caracteres transformados"
          },
          "parâmetros": [
            {
              "nome": "cad",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "um valor qualquer do tipo `cadeia`"
            }
          ]
        },
        {
          "nome": "extrair_subtexto",
          "descrição": "Extrai uma parte da cadeia delimitada pela posição inicial e final. Exemplos:\n\n`extrair_subtexto(\"salgado\", 0, 3) // resultado: \"sal\"`\n\n`extrair_subtexto(\"salgado\", 3, 7) // resultado: \"gado\"`\n\n`extrair_subtexto(\"salgado\", 1, 5) // resultado: \"alga\"`\n",
          "retorno": {
            "tipo": {
              "primitivo": "cadeia"
            },
            "descrição": "uma `cadeia` contendo o subtexto"
          },
          "parâmetros": [
            {
              "nome": "cad",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "a cadeia a partir da qual será extraído o subtexto"
            },
            {
              "nome": "posicao_inicial",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "a posição dentro da cadeia onde começará o subtexto"
            },
            {
              "nome": "posicao_final",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "a posição dentro da cadeia onde terminará o subtexto"
            }
          ]
        },
        {
          "nome": "numero_caracteres",
          "descrição": "Conta o número de caracteres existentes em uma `cadeia`",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "o número de caracteres na `cadeia`"
          },
          "parâmetros": [
            {
              "nome": "cadeia",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "um valor qualquer do tipo `cadeia`"
            }
          ]
        },
        {
          "nome": "obter_caracter",
          "descrição": "Obtém um `caracter` da `cadeia` a partir de seu `índice`.\n\nO `índice` deve estar entre 0 e o número de caracteres da `cadeia`\n",
          "retorno": {
            "tipo": {
              "primitivo": "caracter"
            },
            "descrição": "o `caracter` no `índice` informado"
          },
          "parâmetros": [
            {
              "nome": "cad",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "a `cadeia` da qual será obtido o caracter"
            },
            {
              "nome": "indice",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o índice do caracter que se deseja obter"
            }
          ]
        },
        {
          "nome": "posicao_texto",
          "descrição": "Procura por um texto dentro de uma cadeia e, caso encontrado, retorna a posição da primeira ocorrência",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "a posição da primeira ocorrência do texto, caso ele seja encontrado. Caso o texto não seja encontrado na cadeia o valor retornado é -1"
          },
          "parâmetros": [
            {
              "nome": "texto",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "o texto que será procurado na cadeia"
            },
            {
              "nome": "cad",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "a cadeia dentro da qual o texto será procurado"
            },
            {
              "nome": "posicao_inicial",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "a posição inicial a partir da qual o texto será procurado. Para procurar a partir do início da cadeia deve-se informar a posição 0"
            }
          ]
        },
        {
          "nome": "preencher_a_esquerda",
          "descrição": "Concatena o `caracter` informado, à esquerda da `cadeia`, até que a `cadeia` fique do `tamanho` indicado.\n\nSe o tamanho da `cadeia` for maior ou igual ao `tamanho` informado, nada é feito\n",
          "retorno": {
            "tipo": {
              "primitivo": "cadeia"
            },
            "descrição": "a `cadeia` transformada"
          },
          "parâmetros": [
            {
              "nome": "car",
              "tipo": {
                "primitivo": "caracter"
              },
              "descrição": "o `caracter` que será concatenado à esquerda da `cadeia`"
            },
            {
              "nome": "tamanho",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o tamanho final da `cadeia`"
            },
            {
              "nome": "cad",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "a `cadeia` que será transformada"
            }
          ]
        },
        {
          "nome": "substituir",
          "descrição": "Pesquisa por um determinado texto em uma `cadeia` e substitui todas as ocorrências por um texto alternativo",
          "retorno": {
            "tipo": {
              "primitivo": "cadeia"
            },
            "descrição": "a `cadeia` resultante da substituição"
          },
          "parâmetros": [
            {
              "nome": "cad",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "a `cadeia` que será pesquisada"
            },
            {
              "nome": "texto_pesquisa",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "o texto que será pesquisado na `cadeia`"
            },
            {
              "nome": "texto_substituto",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "o texto pelo qual as ocorrências serão substituídas"
            }
          ]
        }
      ]
    },
    {
      "nome": "Tipos",
      "descrição": "Esta biblioteca contém funções que permitem converter os tipos de dado do Portugol entre si",
      "constantes": [],
      "funções": [
        {
          "nome": "cadeia_e_caracter",
          "descrição": "Verifica se a `cadeia` passada por parâmetro representa um valor do tipo `caracter`\n",
          "retorno": {
            "tipo": {
              "primitivo": "logico"
            },
            "descrição": "`verdadeiro` se a `cadeia` representar um valor do tipo `caracter`, caso contrário, retorna `falso`\n"
          },
          "parâmetros": [
            {
              "nome": "cad",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "a `cadeia` a ser verificada"
            }
          ]
        },
        {
          "nome": "cadeia_e_inteiro",
          "descrição": "Verifica se a `cadeia` passada por parâmetro representa um valor do tipo `inteiro` escrito na notação definida pelo parâmetro `base`\n",
          "retorno": {
            "tipo": {
              "primitivo": "logico"
            },
            "descrição": "`verdadeiro` se a `cadeia` representar um valor do tipo `inteiro`, caso contrário, retorna `falso`\n"
          },
          "parâmetros": [
            {
              "nome": "cad",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "a `cadeia` a ser verificada"
            },
            {
              "nome": "base",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "a base que deverá ser assumida ao realizar a verificação. Os valores possíveis são: 2, 10 e 16.\n\nO valor 2 assume que a `cadeia` representa um número `inteiro` escrito em notação binária.\nEx.: 0b1001; 01101001; 101; 0B1101.\n\nO valor 10 assume que a `cadeia` representa um número `inteiro` escrito em notação decimal.\nEx.: 52; -34; 0; 71.\n\nO valor 16 assume que a `cadeia` representa um número `inteiro` escrito em notação hexadecimal.\nEx.: 0xFF5AC; 0XDf5Ac01B; A0B551ce; ff00ff.\n\nCaso a base informada seja diferente de qualquer um destes valores, será gerado um erro de execução.\n"
            }
          ]
        },
        {
          "nome": "cadeia_e_logico",
          "descrição": "Verifica se a `cadeia` passada por parâmetro representa um valor do tipo `logico`\n",
          "retorno": {
            "tipo": {
              "primitivo": "logico"
            },
            "descrição": "`verdadeiro` se a `cadeia` representar um valor do tipo `logico`, caso contrário, retorna `falso`\n"
          },
          "parâmetros": [
            {
              "nome": "cad",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "a `cadeia` a ser verificada"
            }
          ]
        },
        {
          "nome": "cadeia_e_real",
          "descrição": "Verifica se a `cadeia` passada por parâmetro representa um valor do tipo `real`\n",
          "retorno": {
            "tipo": {
              "primitivo": "logico"
            },
            "descrição": "`verdadeiro` se a `cadeia` representar um valor do tipo `real`, caso contrário, retorna `falso`\n"
          },
          "parâmetros": [
            {
              "nome": "cad",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "a `cadeia` a ser verificada"
            }
          ]
        },
        {
          "nome": "cadeia_para_caracter",
          "descrição": "Converte um valor do tipo `cadeia` em um valor do tipo `caracter`\n",
          "retorno": {
            "tipo": {
              "primitivo": "caracter"
            },
            "descrição": "um valor do tipo `caracter`"
          },
          "parâmetros": [
            {
              "nome": "valor",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "o valor a ser convertido"
            }
          ]
        },
        {
          "nome": "cadeia_para_inteiro",
          "descrição": "Converte um valor do tipo `cadeia` em um valor do tipo `inteiro` utilizando a `base` informada\n",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "um valor do tipo `inteiro`"
          },
          "parâmetros": [
            {
              "nome": "valor",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "o valor a ser convertido"
            },
            {
              "nome": "base",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "a notação em que o número `inteiro` está representado na `cadeia`. Os valores possíveis são: 2, 10 e 16.\n\nO valor 2 assume que a `cadeia` representa um número `inteiro` escrito em notação binária.\nEx.: 0b1001; 01101001; 101; 0B1101.\n\nO valor 10 assume que a `cadeia` representa um número `inteiro` escrito em notação decimal.\nEx.: 52; -34; 0; 71.\n\nO valor 16 assume que a `cadeia` representa um número `inteiro` escrito em notação hexadecimal.\nEx.: 0xFF5AC; 0XDf5Ac01B; A0B551ce; ff00ff.\n\nCaso a base informada seja diferente de qualquer um destes valores, será gerado um erro de execução.\n"
            }
          ]
        },
        {
          "nome": "cadeia_para_logico",
          "descrição": "Converte um valor do tipo `cadeia` em um valor do tipo `logico`\n",
          "retorno": {
            "tipo": {
              "primitivo": "logico"
            },
            "descrição": "um valor do tipo `logico`"
          },
          "parâmetros": [
            {
              "nome": "valor",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "o valor a ser convertido"
            }
          ]
        },
        {
          "nome": "cadeia_para_real",
          "descrição": "Converte um valor do tipo `cadeia` em um valor do tipo `real`\n",
          "retorno": {
            "tipo": {
              "primitivo": "real"
            },
            "descrição": "um valor do tipo `real`"
          },
          "parâmetros": [
            {
              "nome": "valor",
              "tipo": {
                "primitivo": "cadeia"
              },
              "descrição": "o valor a ser convertido"
            }
          ]
        },
        {
          "nome": "caracter_e_inteiro",
          "descrição": "Verifica se o `caracter` passado por parâmetro representa um valor do tipo `inteiro`\n",
          "retorno": {
            "tipo": {
              "primitivo": "logico"
            },
            "descrição": "`verdadeiro` se o `caracter` representar um valor do tipo `inteiro`, caso contrário, retorna `falso`\n"
          },
          "parâmetros": [
            {
              "nome": "car",
              "tipo": {
                "primitivo": "caracter"
              },
              "descrição": "o `caracter` a ser verificado"
            }
          ]
        },
        {
          "nome": "caracter_e_logico",
          "descrição": "Verifica se o `caracter` passado por parâmetro representa um valor do tipo `logico`\n",
          "retorno": {
            "tipo": {
              "primitivo": "logico"
            },
            "descrição": "`verdadeiro` se o `caracter` for um dos seguintes valores: 'S', 's', 'N' ou 'n'. Caso contrário, retorna `falso`\n"
          },
          "parâmetros": [
            {
              "nome": "car",
              "tipo": {
                "primitivo": "caracter"
              },
              "descrição": "o `caracter` a ser verificado"
            }
          ]
        },
        {
          "nome": "caracter_para_cadeia",
          "descrição": "Converte um valor do tipo `caracter` em um valor do tipo `cadeia`\n",
          "retorno": {
            "tipo": {
              "primitivo": "cadeia"
            },
            "descrição": "um valor do tipo `cadeia`"
          },
          "parâmetros": [
            {
              "nome": "valor",
              "tipo": {
                "primitivo": "caracter"
              },
              "descrição": "o valor a ser convertido"
            }
          ]
        },
        {
          "nome": "caracter_para_inteiro",
          "descrição": "Converte um valor do tipo `caracter` em um valor do tipo `inteiro`\n",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "um valor do tipo `inteiro`"
          },
          "parâmetros": [
            {
              "nome": "valor",
              "tipo": {
                "primitivo": "caracter"
              },
              "descrição": "o valor a ser convertido"
            }
          ]
        },
        {
          "nome": "caracter_para_logico",
          "descrição": "Converte um valor do tipo `caracter` em um valor do tipo `logico`\n",
          "retorno": {
            "tipo": {
              "primitivo": "logico"
            },
            "descrição": "`verdadeiro` se o `caracter` for um dos seguintes valores: 'S', 's'; `falso` se o `caracter` for um dos seguintes valores: 'N', 'n'\n"
          },
          "parâmetros": [
            {
              "nome": "valor",
              "tipo": {
                "primitivo": "caracter"
              },
              "descrição": "o valor a ser convertido"
            }
          ]
        },
        {
          "nome": "inteiro_e_caracter",
          "descrição": "Verifica se o `inteiro` passado por parâmetro representa um valor do tipo `caracter`\n",
          "retorno": {
            "tipo": {
              "primitivo": "logico"
            },
            "descrição": "`verdadeiro` se o `inteiro` representar um valor do tipo `caracter`, caso contrário, retorna `falso`\n"
          },
          "parâmetros": [
            {
              "nome": "int",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o `inteiro` a ser verificado"
            }
          ]
        },
        {
          "nome": "inteiro_para_cadeia",
          "descrição": "Converte um valor do tipo `inteiro` em um valor do tipo `cadeia` utilizando a `base` informada\n",
          "retorno": {
            "tipo": {
              "primitivo": "cadeia"
            },
            "descrição": "um valor do tipo `cadeia`"
          },
          "parâmetros": [
            {
              "nome": "valor",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o valor a ser convertido"
            },
            {
              "nome": "base",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "a notação em que o número `inteiro` está representado na `cadeia`. Os valores possíveis são: 2, 10 e 16.\n\nO valor 2 assume que a `cadeia` representa um número `inteiro` escrito em notação binária.\nEx.: 0b1001; 01101001; 101; 0B1101.\n\nO valor 10 assume que a `cadeia` representa um número `inteiro` escrito em notação decimal.\nEx.: 52; -34; 0; 71.\n\nO valor 16 assume que a `cadeia` representa um número `inteiro` escrito em notação hexadecimal.\nEx.: 0xFF5AC; 0XDf5Ac01B; A0B551ce; ff00ff.\n\nCaso a base informada seja diferente de qualquer um destes valores, será gerado um erro de execução.\n"
            }
          ]
        },
        {
          "nome": "inteiro_para_caracter",
          "descrição": "Converte um valor do tipo `inteiro` em um valor do tipo `caracter`\n",
          "retorno": {
            "tipo": {
              "primitivo": "caracter"
            },
            "descrição": "um valor do tipo `caracter`"
          },
          "parâmetros": [
            {
              "nome": "valor",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o valor a ser convertido"
            }
          ]
        },
        {
          "nome": "inteiro_para_logico",
          "descrição": "Converte um valor do tipo `inteiro` em um valor do tipo `logico`\n",
          "retorno": {
            "tipo": {
              "primitivo": "logico"
            },
            "descrição": "`falso` se o valor for menor ou igual a 0, `verdadeiro` se o valor for maior ou igual a 1\n"
          },
          "parâmetros": [
            {
              "nome": "valor",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o valor a ser convertido"
            }
          ]
        },
        {
          "nome": "inteiro_para_real",
          "descrição": "Converte um valor do tipo `inteiro` em um valor do tipo `real`\n",
          "retorno": {
            "tipo": {
              "primitivo": "real"
            },
            "descrição": "um valor do tipo `real`"
          },
          "parâmetros": [
            {
              "nome": "valor",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o valor a ser convertido"
            }
          ]
        },
        {
          "nome": "logico_para_cadeia",
          "descrição": "Converte um valor do tipo `logico` em um valor do tipo `cadeia`\n",
          "retorno": {
            "tipo": {
              "primitivo": "cadeia"
            },
            "descrição": "um valor do tipo `cadeia`"
          },
          "parâmetros": [
            {
              "nome": "valor",
              "tipo": {
                "primitivo": "logico"
              },
              "descrição": "o valor a ser convertido"
            }
          ]
        },
        {
          "nome": "logico_para_caracter",
          "descrição": "Converte um valor do tipo `logico` em um valor do tipo `caracter`\n",
          "retorno": {
            "tipo": {
              "primitivo": "caracter"
            },
            "descrição": "'S' se o valor passado por parâmetro for `verdadeiro`, 'N' se o valor passado por parâmetro for `falso`\n"
          },
          "parâmetros": [
            {
              "nome": "valor",
              "tipo": {
                "primitivo": "logico"
              },
              "descrição": "o valor a ser convertido"
            }
          ]
        },
        {
          "nome": "logico_para_inteiro",
          "descrição": "Converte um valor do tipo `logico` em um valor do tipo `inteiro`\n",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "1 se o valor passado por parâmetro for `verdadeiro`, 0 se o valor passado por parâmetro for `falso`"
          },
          "parâmetros": [
            {
              "nome": "valor",
              "tipo": {
                "primitivo": "logico"
              },
              "descrição": "o valor a ser convertido"
            }
          ]
        },
        {
          "nome": "real_para_cadeia",
          "descrição": "Converte um valor do tipo `real` em um valor do tipo `cadeia`\n",
          "retorno": {
            "tipo": {
              "primitivo": "cadeia"
            },
            "descrição": "um valor do tipo `cadeia`"
          },
          "parâmetros": [
            {
              "nome": "valor",
              "tipo": {
                "primitivo": "real"
              },
              "descrição": "o valor a ser convertido"
            }
          ]
        },
        {
          "nome": "real_para_inteiro",
          "descrição": "Converte um valor do tipo `real` em um valor do tipo `inteiro`. Se o valor `real` tiver uma porção fracionária (Ex.: 9.56), o valor será truncado (Ex.: 9)\n",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "um valor do tipo `inteiro`"
          },
          "parâmetros": [
            {
              "nome": "valor",
              "tipo": {
                "primitivo": "real"
              },
              "descrição": "o valor a ser convertido"
            }
          ]
        }
      ]
    },
    {
      "nome": "Util",
      "descrição": "Esta biblioteca contém diversas funções utilitárias",
      "constantes": [],
      "funções": [
        {
          "nome": "aguarde",
          "descrição": "Pausa a execução do programa durante o intervalo de tempo especificado",
          "retorno": {
            "tipo": {
              "primitivo": "vazio"
            }
          },
          "parâmetros": [
            {
              "nome": "intervalo",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o intervalo de tempo (em milissegundos) durante o qual o programa ficará pausado"
            }
          ]
        },
        {
          "nome": "numero_colunas",
          "descrição": "Descobre o número de colunas existentes em uma matriz",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "O número de colunas existentes na matriz"
          },
          "parâmetros": [
            {
              "nome": "matriz",
              "tipo": {
                "primitivo": "*",
                "dimensão": "matriz"
              },
              "descrição": "a matriz em questão"
            }
          ]
        },
        {
          "nome": "numero_elementos",
          "descrição": "Descobre o número de elementos existentes em um vetor",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "O número de elementos existentes no vetor"
          },
          "parâmetros": [
            {
              "nome": "vetor",
              "tipo": {
                "primitivo": "*",
                "dimensão": "vetor"
              },
              "descrição": "o vetor em questão"
            }
          ]
        },
        {
          "nome": "numero_linhas",
          "descrição": "Descobre o número de linhas existentes em uma matriz",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "O número de linhas existentes na matriz"
          },
          "parâmetros": [
            {
              "nome": "matriz",
              "tipo": {
                "primitivo": "*",
                "dimensão": "matriz"
              },
              "descrição": "a matriz em questão"
            }
          ]
        },
        {
          "nome": "obter_diretorio_usuario",
          "descrição": "Obtém o caminho utilizado pelo Sistema Operacional como diretório do usuário atual",
          "retorno": {
            "tipo": {
              "primitivo": "cadeia"
            },
            "descrição": "O diretório do usuário"
          },
          "parâmetros": []
        },
        {
          "nome": "sorteia",
          "descrição": "Sorteia um número aleatório entre os valores mínimo e máximo especificados",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "O número sorteado"
          },
          "parâmetros": [
            {
              "nome": "minimo",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o menor número que pode ser sorteado"
            },
            {
              "nome": "maximo",
              "tipo": {
                "primitivo": "inteiro"
              },
              "descrição": "o maior número que pode ser sorteado"
            }
          ]
        },
        {
          "nome": "tempo_decorrido",
          "descrição": "Obtém o tempo decorrido (em milissegundos) desde que a biblioteca foi utilizada pela primeira vez",
          "retorno": {
            "tipo": {
              "primitivo": "inteiro"
            },
            "descrição": "O tempo decorrido em milissegundos"
          },
          "parâmetros": []
        }
      ]
    }
  ]
}
